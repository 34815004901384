import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";
import { MedicalStaff } from "modules/in-process/types";

type RoomBedDto = {
  bedId: string;
  roomId: string;
  roomName: string;
  bedName: string;
  bedCode: string;
};

type UsePatientDetailsResponse = {
  id: string;
  fullName: string;
  pictureUrl: string | null;
  allergies: "Yes" | "No" | null;
  charts: string | null;
  roomBed: RoomBedDto | null;
  nurse: MedicalStaff | null;
  provider: MedicalStaff | null;
  supervisor: MedicalStaff | null;
  chargeNurse: MedicalStaff | null;
  admittingProvider: MedicalStaff | null;
  observationProvider: MedicalStaff | null;
  location: string | null;
  devicePairing?: string | null;
  chiefComplaint?: string;
};

function usePatientDetails(encounterId: string) {
  return useAxiosQuery(["patientDetails", { encounterId }], () =>
    axiosPatientInstance.get<UsePatientDetailsResponse>(
      `v1/InProcess/${encounterId}/patient-details`
    )
  );
}

export type { UsePatientDetailsResponse, RoomBedDto };
export { usePatientDetails };
