import { EncounterListItem } from "modules/in-process/types";
import { useInfiniteQuery } from "react-query";
import { PaginationPayload, PaginationResponse } from "types";
import { axiosPatientInstance } from "config";

type UseInProcessEncounterProps = PaginationPayload & {
  patientStatus?: string;
  search?: string;
  searchOpenCharts?: boolean;
  assignation?: InProcessAssignOptions;
};

type InProcessSortOptions = "room" | "createdAt";

type InProcessAssignOptions = "All" | "AssignedToMe" | "Unassigned";

type UseInProcessEncounterResponse = PaginationResponse<EncounterListItem>;

function useInProcessEncounters(props: UseInProcessEncounterProps) {
  const {
    size = 10,
    page = 1,
    search,
    patientStatus,
    sort = "room",
    descending = true,
    assignation = "All",
    searchOpenCharts = false,
  } = props;

  return useInfiniteQuery(
    [
      "inProcessEncounterList",
      {
        search,
        patientStatus,
        sort,
        descending,
        assignation,
        searchOpenCharts,
      },
    ],
    ({ pageParam = page }) =>
      axiosPatientInstance.get<UseInProcessEncounterResponse>("v1/InProcess", {
        params: {
          pageSize: size,
          page: pageParam,
          search,
          patientStatus,
          orderBy: sort || "createdAt",
          sortDesc: descending || false,
          assignation,
          searchOpenCharts,
        },
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.currentPage < lastPage.data.totalPages) {
          return lastPage.data.currentPage + 1;
        }
        return undefined;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.data.currentPage > 1) {
          return firstPage.data.currentPage - 1;
        }
        return undefined;
      },
    }
  );
}

export type {
  UseInProcessEncounterResponse,
  InProcessSortOptions,
  InProcessAssignOptions,
};
export { useInProcessEncounters };
