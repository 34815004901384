import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Logs = createIcon({
  path: (
    <path
      d="M3.62573 1.22357C3.67971 0.431849 4.15654 0 5.01125 0H13.6212C14.4849 0 14.9528 0.431849 15.0157 1.22357H3.62573ZM1.79037 3.82366C1.91633 2.96896 2.34818 2.48313 3.30184 2.48313H15.3396C16.2933 2.48313 16.7251 2.96896 16.8511 3.82366H1.79037ZM2.852 20C0.97166 20 0 19.0283 0 17.175V8.11516C0 6.25281 0.97166 5.29015 2.852 5.29015H15.7805C17.6518 5.29015 18.6325 6.26181 18.6325 8.11516V17.175C18.6325 19.0283 17.6518 20 15.7805 20H2.852Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 18",
});

export { Logs };
