import { axiosChartInstance } from "config";
import {
  OrderFormElementCategory,
  OrderFormElementRoute,
  OrderFormElementType,
} from "enums";
import { useAxiosMutation } from "hooks";
import {
  CommonDose,
  Concentration,
  OrderFormSubElement,
} from "modules/order-list/types";
import { useQueryClient } from "react-query";

type UpdateOrderListElementsPayload = {
  id: string;
  code: string;
  favorite: boolean;
  description: string;
  orderFormElementType: OrderFormElementType;
  orderFormElementCategory: OrderFormElementCategory | null;
  orderFormSubElements: OrderFormSubElement[];
  orderFormElementRoute?: OrderFormElementRoute;
  orderFormElementConcentrations: Concentration[];
  orderFormElementCommonDoses: CommonDose[];
};

function useUpdateOrderListElements(elementType: OrderFormElementType) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateOrderListElements", elementType],
    (payload: UpdateOrderListElementsPayload[]) =>
      axiosChartInstance.post(
        `v1/order-form/elements/add?formElementType=${elementType}`,
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["orderListElements", elementType]);
      },
    }
  );
}

export { useUpdateOrderListElements };
export type { UpdateOrderListElementsPayload };
