import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  chakra,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { EmrPlus } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import { useToast } from "hooks";
import { Edit, QuestionMarkCircle } from "icons";
import { formMessages } from "messages";
import React from "react";
import { MdRemoveCircle } from "react-icons/md";
import {
  Button,
  InfiniteList,
  SearchBar,
  ToolbarHeader,
  WarningDialog,
} from "shared";
import { debounce } from "throttle-debounce";
import { extractApiErrorMessage } from "utils";
import { useDeleteFaq } from "../api";
import { FaqDto, useFaqs } from "../api/queries";
import { FaqFormModal } from "./FaqFormModal";

type FaqModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type FaqItemProps = {
  faq: FaqDto;
};

const FaqHeader = ({ onClose }: FaqModalProps) => {
  const { scope } = usePermissions();

  const addFaqDisclosure = useDisclosure();

  return (
    <ModalHeader>
      <ToolbarHeader
        titleText={
          <Flex alignItems="center">
            <Icon as={QuestionMarkCircle} mr={2} w="24px" h="24px" />
            <chakra.span fontSize="21px" fontWeight="700">
              Frequently Asked Questions
            </chakra.span>
          </Flex>
        }
        leftButtons={[
          scope("faq").isEditable ? (
            <Button
              key="addBtn"
              fontSize="16px"
              fontWeight="500"
              onClick={addFaqDisclosure.onOpen}
            >
              <Icon as={EmrPlus} mr={2} />
              New FAQ
            </Button>
          ) : (
            <></>
          ),
        ]}
        rightButtons={[
          <Button
            key="cancelBtn"
            fontSize="16px"
            fontWeight="500"
            onClick={onClose}
          >
            Done
          </Button>,
        ]}
      />
      <FaqFormModal {...addFaqDisclosure} />
    </ModalHeader>
  );
};

const FaqItem = ({ faq }: FaqItemProps) => {
  const toast = useToast();
  const { scope } = usePermissions();

  const editFaqDisclosure = useDisclosure();
  const warningDisclosure = useDisclosure();

  const { mutateAsync: deleteFaq, isLoading: isDeleteLoading } = useDeleteFaq(
    faq.id
  );

  const handleDeleteFaq = async () => {
    try {
      await deleteFaq({});

      toast({
        status: "success",
        description: formMessages.deleteSuccess("FAQ"),
      });
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  return (
    <AccordionItem border={0}>
      <Flex justifyContent="space-between" alignItems="center">
        <AccordionButton>
          <AccordionIcon w="30px" h="30px" mr="20px" />
          <chakra.span fontSize="21px" fontWeight="600">
            {faq.question}
          </chakra.span>
        </AccordionButton>
        {scope("faq").isEditable && (
          <Flex
            justifyContent="space-around"
            alignItems="center"
            gap={2}
            w="100px"
          >
            <IconButton
              aria-label="edit-faq"
              icon={
                <Icon as={Edit} color="blue" sx={{ h: "25px", w: "25px" }} />
              }
              variant="icon"
              isDisabled={isDeleteLoading}
              onClick={editFaqDisclosure.onOpen}
            />
            <IconButton
              aria-label="delete-faq"
              icon={
                <Icon
                  as={MdRemoveCircle}
                  color="red"
                  sx={{ h: "30px", w: "30px" }}
                />
              }
              isLoading={isDeleteLoading}
              isDisabled={isDeleteLoading}
              variant="icon"
              onClick={warningDisclosure.onOpen}
            />
          </Flex>
        )}
      </Flex>
      <AccordionPanel
        fontSize="17px"
        fontWeight="400"
        pl="68px"
        whiteSpace="pre"
      >
        {faq.answer}
      </AccordionPanel>
      <FaqFormModal {...editFaqDisclosure} faq={faq} />
      <WarningDialog
        isOpen={warningDisclosure.isOpen}
        title="Warning!"
        mainText="Are you sure you want to remove this Frequently Asked Question?"
        actionLabel="OK"
        actionButtonProps={{ color: "red" }}
        onCancel={warningDisclosure.onClose}
        cancelButtonProps={{ color: "gray.700" }}
        onAction={handleDeleteFaq}
        onClose={warningDisclosure.onClose}
      />
    </AccordionItem>
  );
};

const FaqModalContent = () => {
  const toast = useToast();

  const [search, setSearch] = React.useState("");

  const onSearch = React.useMemo(() => debounce(1000, setSearch), []);

  const { data, isLoading, fetchNextPage, hasNextPage, error, isFetching } =
    useFaqs({
      search,
      size: 10,
    });

  const rows = React.useMemo(
    () =>
      data?.pages.reduce<FaqDto[]>(
        (accum, curr) => [...accum, ...curr.data.content],
        []
      ) || [],
    [data]
  );

  const fetchMore = React.useCallback(async () => {
    try {
      await fetchNextPage();
    } catch (err) {
      toast({ description: extractApiErrorMessage(err) });
    }
  }, [fetchNextPage, toast]);

  React.useEffect(() => {
    if (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  }, [error, toast]);

  return (
    <>
      <SearchBar
        placeholder="Search"
        onChange={(e) => {
          onSearch(e.target.value);
        }}
      />
      <Box my={5} h="calc(100% - 100px)">
        <Accordion allowMultiple allowToggle h="100%">
          <InfiniteList
            fetchMore={fetchMore}
            hasMore={!!hasNextPage}
            isLoading={isLoading || isFetching}
            renderRow={(faq) => <FaqItem key={faq.id} faq={faq} />}
            rows={rows}
            doNotUseContainerStyle
          />
        </Accordion>
      </Box>
    </>
  );
};

const FaqModal = (props: FaqModalProps) => {
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent
        bg="white"
        maxW="unset"
        width="1200px"
        height="95%"
        margin="auto"
        overflowY="auto"
        overflowX="hidden"
      >
        <FaqHeader {...props} />
        <ModalBody p="20px">
          <FaqModalContent />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { FaqModal };
