import * as React from "react";
import DatePicker, {
  ReactDatePickerProps,
  CalendarContainer,
} from "react-datepicker";
import {
  Flex,
  Box,
  chakra,
  Select,
  InputGroup,
  InputRightElement,
  Icon,
  Input,
  useFormControl,
} from "@chakra-ui/react";
import { getYear, getMonth, add } from "date-fns";
import { EmrCalendar } from "@medstonetech/slate-icons";
import { AnyObject } from "types/utils";
import { range } from "../../utils/helpers";
import { CalendarTimeInput } from "./CalendarTimeInput";
import InputMask from "react-input-mask";

import "react-datepicker/dist/react-datepicker.css";
import "./calendarInput.css";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const CkCalendarContainer = chakra(CalendarContainer);

type InnerProps = {
  minDate?: Date;
  maxDate?: Date;
  showIcon?: boolean;
};

type CalendarInputProps = InnerProps &
  Omit<ReactDatePickerProps, keyof InnerProps>;

const CustomInput = React.forwardRef<HTMLInputElement, AnyObject>(
  (props, ref) => {
    const { showIcon, ...restProps } = props;

    return (
      <InputGroup>
        <Input ref={ref} {...restProps} />
        {showIcon ? (
          <InputRightElement>
            <Icon as={EmrCalendar} color="gray.700" fontSize="1.25rem" />
          </InputRightElement>
        ) : null}
      </InputGroup>
    );
  }
);

CustomInput.displayName = "CalendarCustomInput";

const CalendarInput = React.forwardRef<DatePicker, CalendarInputProps>(
  (props, ref) => {
    const {
      minDate = new Date(1900, 0, 1),
      maxDate = add(new Date(), { years: 10 }),
      dateFormat = "MM-dd-yyyy",
      showIcon = true,
    } = props;

    const formControl = useFormControl(props);

    const years = range(getYear(minDate), getYear(maxDate) + 1);

    return (
      <DatePicker
        ref={ref}
        {...props}
        {...formControl}
        open={false}
        customTimeInput={<CalendarTimeInput />}
        timeInputLabel=""
        dateFormat={dateFormat}
        dateFormatCalendar="MM-dd-yyyy"
        renderCustomHeader={({ date, changeMonth, changeYear }) => (
          <Box mx="15px" mb="10px" mt="6px">
            <Flex justifyContent="space-between">
              <Select
                value={months[getMonth(date)]}
                variant="unstyled"
                fontSize="20px"
                fontWeight="bold"
                width="auto"
                onChange={(e) => {
                  changeMonth(months.indexOf(e.target.value));
                }}
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
              <Select
                value={getYear(date)}
                variant="unstyled"
                fontSize="20px"
                fontWeight="bold"
                width="auto"
                onChange={(e) => {
                  changeYear(parseInt(e.target.value, 10));
                }}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </Flex>
          </Box>
        )}
        customInput={<CustomInput showIcon={showIcon} />}
        showPopperArrow={false}
        formatWeekDay={(nameOfDay) => {
          return nameOfDay.substring(0, 3).toUpperCase();
        }}
        calendarContainer={({ children, ...calendarProps }) => (
          <CkCalendarContainer
            {...calendarProps}
            fontFamily="body"
            border="1px solid #D1D1D6"
            boxShadow="0px 4px 4px rgba(50, 50, 71, 0.08), 0px 4px 8px rgba(50, 50, 71, 0.06)"
            borderRadius="13px"
            fontSize="unset"
            padding="1"
          >
            {children}
          </CkCalendarContainer>
        )}
      />
    );
  }
);

CalendarInput.displayName = "CalendarInput";

const MaskedCalendarInput = React.forwardRef<HTMLInputElement, AnyObject>(
  (props, ref) => {
    // eslint-disable-next-line
    const { onChange: onChangeProp, ...restProps } = props as any;

    // eslint-disable-next-line
    const defaultValue = (restProps as any).control.watchInternal(
      restProps.name
    );

    const [controlledValue, setControlledValue] = React.useState(
      defaultValue as string
    );

    React.useEffect(() => {
      setControlledValue(defaultValue);
    }, [defaultValue, setControlledValue]);

    const validateInput: (date: string) => boolean = (date: string) => {
      const [month, day, year] = date.split("-").map(Number);
      const isValidMonth =
        date.length === 1
          ? month <= 1
          : date.length < 2 || (month >= 1 && month <= 12);
      const isValidDay =
        date.length === 4
          ? day <= 3
          : date.length < 5 || (day >= 1 && day <= 31);
      const isValidYear =
        date.length < 10 || (year >= 1900 && year <= new Date().getFullYear());
      return isValidMonth && isValidDay && isValidYear;
    };

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
      const inputValue = event.target.value;

      if (validateInput(inputValue)) {
        onChangeProp(event);
        setControlledValue(inputValue);
      }
    };

    return (
      <InputGroup ref={ref}>
        <Input
          as={InputMask}
          mask="99-99-9999"
          maskChar={null}
          value={controlledValue ?? ""}
          onChange={onChange}
          placeholder="MM-DD-YYYY"
          {...restProps}
        />
        <InputRightElement>
          <Icon as={EmrCalendar} color="gray.700" fontSize="1.25rem" />
        </InputRightElement>
      </InputGroup>
    );
  }
);

MaskedCalendarInput.displayName = "MaskedCalendarInput";

export { CalendarInput, MaskedCalendarInput };
export type { CalendarInputProps };
