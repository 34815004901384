import { Box, chakra, Flex, Icon, IconButton, Text } from "@chakra-ui/react";
import { EmrProfileComplete, EmrRemove } from "@medstonetech/slate-icons";
import { UseUserByScopeResponseItem } from "api";
import { useToast } from "hooks";
import { PersonAdd } from "icons";
import { formMessages } from "messages";
import { useDeleteProgressNote } from "modules/charts-shared/api";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { Button, Card, CardProps, StaffAvatar, UserAvatar } from "shared";
import { ChartProgressItem } from "types";
import { enumMapper, extractApiErrorMessage } from "utils";
import { ChartRowElement } from "../chart";
import { AddTransferCareProviderModal } from "./AddTransferCareProviderModal";

type ProgressCardProps = {
  name: string;
  onDelete: () => void;
  progressNote: Omit<ChartProgressItem, "encounterId">;
} & CardProps;

const GRID_PROPS: CardProps = {
  display: "grid",
  gridTemplateColumns: "32px 200px 1fr 47px",
  gridColumnGap: "18px",
  gridRowGap: "8px",
  alignItems: "center",
};

function ProgressCardHeader(props: CardProps) {
  return (
    <Card
      padding="16px"
      bg="gray.250"
      color="gray.650"
      fontSize="0.9375rem"
      boxShadow="none"
      borderRadius="5px"
      fontWeight="600"
      {...GRID_PROPS}
      {...props}
    >
      <Icon
        as={EmrProfileComplete}
        w="1.25rem"
        h="1.25rem"
        color="gray.650"
        justifySelf="center"
      />
      <chakra.span textAlign="center">Date / Time</chakra.span>
      <chakra.span textAlign="left">Status</chakra.span>
      <chakra.span textAlign="center">Delete</chakra.span>
    </Card>
  );
}

const progressOptions = enumMapper.getOptions("chartProgressStatus");

function ProgressCard(props: ProgressCardProps) {
  const {
    name,
    progressNote: { id, createdByFullName, createdByProfilePictureUrl, type },
    onDelete: onDeleteProp,
    ...cardProps
  } = props;
  const [addingProvider, setAddingProvider] = React.useState(false);
  const { setValue, watch } = useFormContext();
  const toast = useToast();
  const { mutateAsync: deleteNote, isLoading: isDeleteLoading } =
    useDeleteProgressNote();
  const onDelete = async () => {
    try {
      await deleteNote(id);
      onDeleteProp();
      toast({ description: formMessages.deleteSuccess("Progress Note") });
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const transferredTo = watch(`${name}.transferredTo`);
  const transferredToEntity = watch(`${name}.transferredToEntity`);

  const handleSelectProvider = (provider: UseUserByScopeResponseItem) => {
    setValue(`${name}.transferredTo`, provider.id);
    setValue(`${name}.transferredToEntity`, provider);
  };

  return (
    <Card
      padding="16px"
      boxShadow="none"
      border="none"
      {...GRID_PROPS}
      {...cardProps}
    >
      <StaffAvatar
        size="xs"
        fontSize="1rem"
        userName={createdByFullName || ""}
        profileUrl={createdByProfilePictureUrl || ""}
        justifySelf="center"
      />
      <ChartRowElement type="date" name={`${name}.date`} />
      {type === "note" ? (
        <Flex columnGap="0.5rem">
          {progressOptions.map((option) => (
            <ChartRowElement
              type="option"
              name={`${name}.${option.value}`}
              label={option.label}
              minW="max-content"
            />
          ))}
        </Flex>
      ) : (
        <Flex>
          <Flex flex={4} columnGap="30px" alignItems="center">
            <Text fontWeight="bold">Care transferred to:</Text>
            {transferredTo ? (
              <Flex alignItems="center">
                <UserAvatar
                  mr="11px"
                  w="36px"
                  h="36px"
                  picture={transferredToEntity.pictureUrl}
                  name={transferredTo.fullName}
                />
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize="1.063rem"
                    lineHeight="1.063rem"
                  >
                    {transferredToEntity.fullName}
                  </Text>
                  <Text
                    fontSize="0.875rem"
                    lineHeight="0.875rem"
                    color="gray"
                    mt="2px"
                  >
                    Provider
                  </Text>
                </Box>
              </Flex>
            ) : (
              <Button
                variant="label"
                color="blue"
                onClick={() => setAddingProvider(true)}
              >
                <Icon as={PersonAdd} mr="12px" />
                Add Provider
              </Button>
            )}
          </Flex>
        </Flex>
      )}
      <IconButton
        aria-label="delete progress note"
        icon={<EmrRemove color="red" />}
        variant="icon"
        justifySelf="center"
        onClick={onDelete}
        isLoading={isDeleteLoading}
      />
      <ChartRowElement
        type="input"
        name={`${name}.notes`}
        gridColumn="1 / -1"
      />
      <AddTransferCareProviderModal
        onClose={() => setAddingProvider(false)}
        onSelectedProvider={handleSelectProvider}
        isOpen={addingProvider}
        progressNoteId={id}
      />
    </Card>
  );
}

export { ProgressCard, ProgressCardHeader };
