import {
  Box,
  Button,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { EmrCheck } from "@medstonetech/slate-icons";
import { AxiosResponse } from "axios";
import { useInputMenu, useToast } from "hooks";
import {
  EncounterUserType,
  UseUsersResponseItem,
  UserType,
  useUsers,
} from "modules/identity";
import { useAssignUser } from "modules/in-process/api";
import {
  EncounterAssignResponse,
  MedicalStaff,
} from "modules/in-process/types";
import * as React from "react";
import { BackButton, DividerList, Loading, SearchBar } from "shared";
import { debounce } from "throttle-debounce";
import { extractApiErrorMessage } from "utils";
import { PersonalInfo, PersonalInfoSelectedCard } from "./PersonalInfo";
import { useUpdateIntegrationsStatus } from "modules/in-process/hooks";

type MedicalStaffModalProps = React.PropsWithChildren<
  Pick<UseDisclosureReturn, "isOpen" | "onClose">
>;

type MedicalStaffModalBaseProps = Pick<
  UseDisclosureReturn,
  "isOpen" | "onClose"
> & {
  onChange?: (value: MedicalStaff | null) => void;
  initialValue?: MedicalStaff | null;
  encounterId: string;
  encounterUserType: EncounterUserType;
  userType: UserType;
  onCloseDetailsModal: () => void;
};

type MedicalStaffModalContentProps = Pick<
  MedicalStaffModalBaseProps,
  "onChange" | "initialValue" | "onCloseDetailsModal"
> &
  Pick<MedicalStaffModalProps, "onClose"> & {
    encounterId: string;
    encounterUserType: EncounterUserType;
    userType: UserType;
    title: string;
  } & { hideDetailsButton?: boolean };

type MedicalStaffInputProps = {
  value?: MedicalStaff | null;
  defaultValue?: MedicalStaff | null;
  onChange?: (newValue: MedicalStaff | null) => void;
  encounterId: string;
  encounterUserType: EncounterUserType;
  userType: UserType;
  onCloseDetailsModal: () => void;
  disabled?: boolean;
};

const idAccessor = (value: MedicalStaff | null) => value?.id || "";

function MedicalStaffModalContent(props: MedicalStaffModalContentProps) {
  const {
    initialValue,
    onChange,
    onClose,
    encounterUserType,
    userType,
    title,
    encounterId,
    onCloseDetailsModal,
    hideDetailsButton,
  } = props;
  const [search, setSearch] = React.useState("");
  const debouncedSearch = debounce(1000, setSearch);

  const {
    data,
    isLoading: isUsersLoading,
    error: usersError,
  } = useUsers({ userType, search });

  const { mutateAsync: assignPtUser, isLoading: isAssignLoading } =
    useAssignUser(encounterId);

  const { isLoading: isLoadingIntegrationStatus, updateIntegrationStatus } =
    useUpdateIntegrationsStatus(encounterId);

  const toast = useToast();
  const assignUser = React.useCallback(
    async (newValue: UseUsersResponseItem | null) => {
      try {
        let encounterAssignResponse: AxiosResponse<EncounterAssignResponse>;

        if (newValue) {
          encounterAssignResponse = await assignPtUser({
            type: encounterUserType,
            userId: newValue.id,
          });
          toast({ description: "User assigned successfully." });
        } else {
          encounterAssignResponse = await assignPtUser({
            type: encounterUserType,
            userId: null,
          });
          toast({ description: "User unassigned successfully." });
        }
        onChange?.(newValue);

        if (!isLoadingIntegrationStatus) {
          updateIntegrationStatus(
            encounterAssignResponse.data?.status ?? "Lobby"
          );
        }
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      assignPtUser,
      encounterUserType,
      onChange,
      toast,
      updateIntegrationStatus,
      isLoadingIntegrationStatus,
    ]
  );
  const {
    getMenuItemProps,
    getMenuProps,
    selectedItem,
    setSelectedItem,
    highlightedIndex,
  } = useInputMenu({
    items: data?.data || [],
    defaultSelectedItem: initialValue,
    idAccessor,
    onSelectedItemChange: assignUser,
  });
  const personalInfo = React.useMemo(
    () =>
      selectedItem && {
        description: selectedItem.teams,
        name: selectedItem.fullName,
        pictureUrl: selectedItem.pictureUrl,
      },
    [selectedItem]
  );

  React.useEffect(() => {
    if (usersError) {
      toast({ description: extractApiErrorMessage(usersError) });
    }
  }, [usersError, toast]);

  return (
    <>
      <ModalHeader
        display="flex"
        justifyContent="space-between"
        fontSize="1rem"
        padding="20px 15px 0"
      >
        {!hideDetailsButton && (
          <BackButton onClick={onClose}>Details</BackButton>
        )}
        <Heading fontWeight="600" fontSize="1.0625rem">
          {title}
        </Heading>
        <Button variant="label" onClick={onCloseDetailsModal} color="blue">
          Close
        </Button>
      </ModalHeader>
      <ModalBody
        padding="34px 15px 15px"
        display="flex"
        flexDirection="column"
        overflow="hidden"
      >
        <Heading fontSize="0.9375rem" marginBottom="10px">
          Selected
        </Heading>
        <PersonalInfoSelectedCard
          onDelete={() => setSelectedItem(null)}
          personalInfo={personalInfo}
        />
        <Box margin="20px 0">
          <SearchBar
            placeholder="Search"
            height="36px"
            bg="gray.350"
            onChange={(e) => debouncedSearch(e.target.value)}
          />
        </Box>
        {isUsersLoading ? (
          <Loading />
        ) : (
          <Box
            borderRadius="10px"
            bg="white"
            _focus={{
              boxShadow: "var(--chakra-shadows-outline)",
              outline: "none",
            }}
            outline="none"
            opacity={isAssignLoading ? 0.6 : 1}
            width="510px"
            overflow="auto"
            {...getMenuProps()}
          >
            <DividerList dividerLeftMargin="62px">
              {data?.data.map((item, index) => (
                <Box
                  padding="15px 10px"
                  key={item.id}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="52px"
                  maxHeight="52px"
                  _selected={{ bg: "blue", color: "white" }}
                  {...getMenuItemProps({ item, index })}
                >
                  <PersonalInfo
                    name={item.fullName}
                    pictureUrl={item.pictureUrl}
                    description={item.teams}
                    isHighlighted={index === highlightedIndex}
                  />
                  {idAccessor(item) === idAccessor(selectedItem) &&
                    !isAssignLoading && (
                      <Icon
                        as={EmrCheck}
                        fontSize="1rem"
                        color={index === highlightedIndex ? "white" : "blue"}
                      />
                    )}
                  {idAccessor(item) === idAccessor(selectedItem) &&
                    isAssignLoading && <Spinner />}
                </Box>
              ))}
            </DividerList>
          </Box>
        )}
      </ModalBody>
    </>
  );
}

function MedicalStaffModal(props: MedicalStaffModalProps) {
  const { isOpen, onClose, children } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        width="540px"
        maxW="unset"
        bg="gray.200"
        containerProps={{ justifyContent: "flex-end", alignItems: "center" }}
        height="98%"
        margin="0"
        mr="10px"
      >
        {children}
      </ModalContent>
    </Modal>
  );
}

export { MedicalStaffModal, MedicalStaffModalContent };
export type {
  MedicalStaff,
  MedicalStaffInputProps,
  MedicalStaffModalBaseProps,
  MedicalStaffModalContentProps,
  MedicalStaffModalProps,
};
