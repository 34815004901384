import * as React from "react";
import {
  VitalsInputModal,
  VitalsInputModalKeypad,
  VitalsInputModalValueBox,
  BaseVitalsInputModalProps,
} from "./VitalsInputModal";
import { Button } from "@chakra-ui/react";
import { Close } from "icons";

function BaseVitalsInputModal(props: BaseVitalsInputModalProps) {
  const { onSave, onValidate, initialValue, ...vitalsInputModalProps } = props;
  const { onClose } = vitalsInputModalProps;
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");
  const internalOnSave = () => {
    if (onValidate) {
      const validate = onValidate(value);

      if (typeof validate === "string") {
        setError(validate);
        return;
      }
    }
    onSave(value);
    onClose();
  };

  React.useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <VitalsInputModal {...vitalsInputModalProps}>
      <VitalsInputModalValueBox error={error}>{value}</VitalsInputModalValueBox>
      <VitalsInputModalKeypad
        onClickKey={(x) => setValue((prev) => `${prev}${x}`)}
        onSave={internalOnSave}
        onDelete={() => setValue((prev) => prev.slice(0, prev.length - 1))}
      />
      <Button
        onClick={onClose}
        variant="solid"
        m={0}
        minW="unset"
        backgroundColor="gray.350"
        w="30px"
        h="30px"
        position="absolute"
        top={3}
        right={3}
        maxH="unset"
        colorScheme="gray"
      >
        <Close width="10px" height="10px" color="gray.600" />
      </Button>
    </VitalsInputModal>
  );
}

export { BaseVitalsInputModal };
