import { Box, Grid, HStack, Icon } from "@chakra-ui/react";
import {
  EmrKey,
  EmrLabs,
  EmrPerson,
  EmrRadiology,
  EmrRoomCard,
} from "@medstonetech/slate-icons";
import background from "assets/background-welcome.jpg";
import { usePermissions } from "contexts";
import { useUserRoles } from "contexts/UserRoles";
import {
  Audits,
  CheckmarkShield,
  LabsList,
  MedicalRecords,
  MedicationList,
  OpenCharts,
  Permissions,
  QuestionMarkCircle,
  RoomList,
  StpVipList,
  Tracking,
} from "icons";
import {
  IncompleteAuditsNavigationCard,
  OpenChartsNavigationCard,
} from "./NavigationCard";
import { ModuleLink, ModuleLinkProps } from "./ModuleLink";

import { CloudCheck } from "icons/CloudCheck";
import { CurrentDateTime } from "./CurrentDateTime";
import { Outlet } from "react-router-dom";
import { PasswordsManager } from "./passwords-manager";
import { RadiologyList } from "icons/RadiologyList";
import { UserCard } from "shared/user-card";
import { UserType } from "modules/identity";

function Onboarding() {
  const { roles } = useUserRoles();
  const { scope } = usePermissions();

  const MODULES: ModuleLinkProps[] = [
    {
      to: "/reception",
      moduleIcon: <Icon as={EmrKey} />,
      label: "Reception",
      bg: "linear-gradient(180deg, #7CDBFA 0%, #5AC9ED 100%)",
      hasAccess: scope("reception").isAccessible,
    },
    {
      to: "/in-process",
      moduleIcon: <Icon as={EmrPerson} />,
      label: "In Process",
      bg: "linear-gradient(180deg, #3573E7 0%, #1A5BD6 100%);",
      hasAccess: scope("inprocess").isAccessible,
    },
    {
      to: "/labs",
      moduleIcon: <Icon as={EmrLabs} />,
      label: "Labs",
      bg: "linear-gradient(180deg, #FD9840 0%, #F07A13 100%)",
      hasAccess: scope("labs").isAccessible,
    },
    {
      to: "/radiology",
      moduleIcon: <Icon as={EmrRadiology} />,
      label: "Radiology",
      bg: "linear-gradient(180deg, #9557F3 0%, #692FC2 100%)",
      hasAccess: scope("radiology").isAccessible,
    },
    {
      to: "/audits",
      moduleIcon: <Icon as={Audits} />,
      label: "Audits",
      bg: "#FF9500",
      hasAccess: scope("audits").isAccessible,
    },
    {
      to: "/open-charts",
      moduleIcon: <Icon as={OpenCharts} />,
      label: "Open Charts",
      bg: "red",
      hasAccess: scope("opencharts").isAccessible,
    },
    {
      to: "/permissions",
      moduleIcon: <Icon as={Permissions} />,
      label: "Permissions",
      bg: "green",
      hasAccess: scope("permission").isAccessible,
    },
    {
      to: "/stp-vip",
      moduleIcon: <Icon as={StpVipList} />,
      label: "STP/VIP List",
      bg: "red",
      hasAccess: scope("stpvip").isAccessible,
    },
    {
      to: "/medical-records",
      moduleIcon: <Icon as={MedicalRecords} />,
      label: "Medical Records",
      bg: "mint",
      hasAccess: scope("medicalrecords").isAccessible,
    },
    {
      to: "/home/labs-list",
      moduleIcon: <Icon as={LabsList} />,
      label: "Labs List",
      bg: "yellow",
      hasAccess: scope("labslist").isAccessible,
    },
    {
      to: "/home/radiology-list",
      moduleIcon: <Icon as={RadiologyList} />,
      label: "Radiology List",
      bg: "red",
      hasAccess: scope("radiologylist").isAccessible,
    },
    {
      to: "/home/medication-list",
      moduleIcon: <Icon as={MedicationList} />,
      label: "Medication List",
      bg: "mint",
      hasAccess: scope("medicationlist").isAccessible,
    },
    {
      to: "/home/logs",
      moduleIcon: <Icon as={Tracking} />,
      label: "Logs",
      bg: "mint",
      hasAccess: scope("logs").isAccessible,
    },
    {
      to: "/insurance-payers",
      moduleIcon: <Icon as={CheckmarkShield} />,
      label: "Insurance Payers",
      bg: "linear-gradient(180deg, #9557F3 0%, #692FC2 100%)",
      hasAccess: scope("insurancepayers").isAccessible,
    },
    {
      to: "/home/room-management",
      moduleIcon: <Icon as={EmrRoomCard} />,
      label: "Rooms Management",
      bg: "#32ADE6",
      hasAccess: scope("roomsmanagement").isAccessible,
    },
    {
      to: "/home/room-list",
      moduleIcon: <Icon as={RoomList} />,
      label: "Rooms List",
      bg: "#32ADE6",
      hasAccess: scope("roomslist").isAccessible,
    },
    {
      to: "/whitelist",
      moduleIcon: <Icon as={CloudCheck} />,
      label: "Whitelist",
      bg: "green",
      hasAccess: scope("ipwhitelist").isAccessible,
    },
    {
      to: "/home/faq",
      moduleIcon: <Icon as={QuestionMarkCircle} />,
      label: "FAQ",
      bg: "blue",
      hasAccess: scope("faq").isAccessible,
    },
    // {
    //   to: "/teams",
    //   moduleIcon: <Icon as={Teams} />,
    //   label: "Teams",
    //   bg: "linear-gradient(180deg, #7CDBFA 0%, #5AC9ED 100%)",
    //   isEnabled: false,
    // },
    // {
    //   to: "/hospitality",
    //   moduleIcon: <Icon as={PersonRaisedHand} />,
    //   label: "Hospitality",
    //   bg: "linear-gradient(180deg, #DE5494 0%, #CF4485 100%)",
    //   isEnabled: false,
    // },
    // {
    //   to: "/billing",
    //   moduleIcon: <Icon as={Bill} />,
    //   label: "Billing",
    //   bg: "linear-gradient(180deg, #0BB839 0%, #058527 100%)",
    //   isEnabled: false,
    // },
    // {
    //   to: "/task-manager",
    //   moduleIcon: <Icon as={CheckList} />,
    //   label: "Task Manager",
    //   bg: "linear-gradient(180deg, #FD9840 0%, #F07A13 100%)",
    //   isEnabled: false,
    // },
    // {
    //   to: "/callbacks",
    //   moduleIcon: <Icon as={Phone} />,
    //   label: "Callbacks",
    //   bg: "linear-gradient(180deg, #0BB839 0%, #058527 100%)",
    //   isEnabled: false,
    // },
    // {
    //   to: "/maintenance",
    //   moduleIcon: <Icon as={Tools} />,
    //   label: "Maintenance",
    //   bg: "linear-gradient(180deg, #3573E7 0%, #1A5BD6 100%)",
    //   isEnabled: false,
    // },
    // {
    //   to: "/cleaning",
    //   moduleIcon: <Icon as={Sparkles} />,
    //   label: "Cleaning",
    //   bg: "linear-gradient(180deg, #9557F3 0%, #692FC2 100%)",
    //   isEnabled: false,
    // },
    // {
    //   to: "/kitchen",
    //   moduleIcon: <Icon as={EmrKitchen} />,
    //   label: "Kitchen",
    //   bg: "linear-gradient(180deg, #DB4B3D 0%, #D03728 100%)",
    //   isEnabled: false,
    // },
    // {
    //   to: "/reports",
    //   moduleIcon: <Icon as={BarGraph} />,
    //   label: "Reports",
    //   bg: "linear-gradient(180deg, #7CDBFA 0%, #5AC9ED 100%)",
    //   isEnabled: false,
    // },
    // {
    //   to: "/supplies",
    //   moduleIcon: <Icon as={EmrCrossCase} />,
    //   label: "Supplies",
    //   bg: "linear-gradient(180deg, #FDCE30 0%, #F8C109 100%)",
    //   isEnabled: false,
    // },
  ];

  const rolesAllowedToViewOpenChartCounter: UserType[] = [
    "Administrator",
    "SupervisingProvider",
    "Provider",
    "Nurse",
  ];

  return (
    <Box
      display="flex"
      padding="3.5rem"
      height="100%"
      bgImage={background}
      bgPos="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <Box width="30rem" marginRight="5rem">
        <CurrentDateTime marginBottom="2rem" />
        <UserCard marginBottom="1rem" flexDir="column" />

        <HStack w="100%" alignItems="start" gap="1rem">
          {roles.some((role) =>
            rolesAllowedToViewOpenChartCounter.includes(role)
          ) && (
            <OpenChartsNavigationCard marginBottom="1rem" flexDir="column" />
          )}

          {roles.some((role) =>
            rolesAllowedToViewOpenChartCounter.concat("Auditor").includes(role)
          ) && (
            <IncompleteAuditsNavigationCard
              marginBottom="1rem"
              flexDir="column"
            />
          )}
        </HStack>
      </Box>
      {/**
       * @todo - Add modules to grid once each module
       * development is done
       */}
      <Grid
        flex={1}
        columnGap="2rem"
        gridTemplateColumns="repeat(5, 1fr)"
        rowGap="2rem"
        height="fit-content"
        maxHeight="100%"
        overflow="auto"
        paddingTop="25px"
      >
        {MODULES.map(
          (module) =>
            module.hasAccess && <ModuleLink key={module.to} {...module} />
        )}
        {scope("resetpassword").isAccessible && <PasswordsManager />}
      </Grid>
      <Outlet />
    </Box>
  );
}
export { Onboarding };
