import {
  Box,
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { EmrDocument } from "@medstonetech/slate-icons";
import { useOrderFormFavorites } from "modules/charts-shared/api/queries/useOrderFormFavorites";
import {
  ChartRouteBaseParams,
  OrderItem,
  SharedChartBaseProps,
} from "modules/charts-shared/types";
import { OrderElementResponse } from "modules/order-list/types";
import * as React from "react";
import { useParams } from "react-router-dom";
import { AddOrderItemModal } from "../AddOrderItemModal";
import { ChartSectionHeader } from "../chart";
import { OrderFormCart } from "../order-form-cart/OrderFormCart";
import { OrderFormList } from "./OrderFormList";
import { Button } from "shared";
import { MdChevronLeft } from "react-icons/md";
import { AddMedicationOrderModal } from "../add-medication-order-modal";

const sectionId = "SPOF";

type OrderFormProps = SharedChartBaseProps & {
  onBack?: () => void;
};

function OrderForm(props: OrderFormProps) {
  const { chartCode, chartType, onBack, isReadOnly = false } = props;
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const { data } = useOrderFormFavorites();
  const favorites = React.useMemo(() => (data ? data.data : []), [data]);
  const [items, setItems] = React.useState<OrderItem[]>([]);
  const [addingItem, setAddingItem] =
    React.useState<OrderElementResponse | null>(null);

  const handleAddItem = (item: OrderItem) => {
    setItems((prev) => [...prev, item]);
  };

  const handleAddingItem = (item: OrderElementResponse) => {
    setAddingItem(item);
  };

  const handleDeleteCartItem = (id: string) => {
    setItems((prev) => prev.filter((i) => i.id !== id));
  };

  const handleClear = () => {
    setItems([]);
    onBack?.();
  };

  return (
    <Box height="100%" w="100%">
      {Boolean(onBack) && (
        <Button
          variant="label"
          ml="20px"
          color="blue"
          mt="20px"
          onClick={onBack}
        >
          <MdChevronLeft fontSize="2rem" />
          Back
        </Button>
      )}
      <Flex height="100%" w="100%">
        <Box
          flex={2}
          overflow="auto"
          overflowX="hidden"
          p="20px"
          borderRight="1px solid"
          borderColor="gray.200"
        >
          <ChartSectionHeader
            icon={<Icon as={EmrDocument} />}
            chartId={chartCode}
            encounterId={encounterId}
            sectionId={sectionId}
            showNotesBtn={chartType === "Chart" || chartType === "Triage"}
          >
            Order Form {isReadOnly ? "(Read Only)" : ""}
          </ChartSectionHeader>
          <Tabs isFitted margin="0.15rem">
            <TabList>
              <Tab>Labs</Tab>
              <Tab>Radiology</Tab>
              <Tab>Medications</Tab>
              {/* <Tab>Actions & Procedures</Tab> */}
            </TabList>
            <TabPanels>
              <TabPanel>
                <OrderFormList
                  onAddItem={handleAddingItem}
                  type="Lab"
                  favorites={favorites}
                />
              </TabPanel>
              <TabPanel>
                <OrderFormList
                  onAddItem={handleAddingItem}
                  type="Radiology"
                  favorites={favorites}
                />
              </TabPanel>
              <TabPanel>
                <OrderFormList
                  onAddItem={handleAddingItem}
                  type="Medication"
                  favorites={favorites}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <Box
          flex={1}
          pt="35px"
          px="20px"
          display="flex"
          flexDirection="column"
          overflow="auto"
          backgroundColor="gray.200"
        >
          <OrderFormCart
            items={items}
            onDelete={handleDeleteCartItem}
            onClear={handleClear}
            chartCode={chartCode}
          />
        </Box>
        <AddOrderItemModal
          isOpen={
            Boolean(addingItem) &&
            addingItem?.orderFormElementType !== "Medication"
          }
          onClose={() => setAddingItem(null)}
          onAddItem={handleAddItem}
          item={addingItem}
          favorite={favorites.find(
            (fav) => fav.orderFormElementId === addingItem?.id
          )}
        />
        {addingItem && (
          <AddMedicationOrderModal
            isOpen={
              Boolean(addingItem) &&
              addingItem?.orderFormElementType === "Medication"
            }
            onAddItem={handleAddItem}
            onClose={() => setAddingItem(null)}
            item={addingItem}
          />
        )}
      </Flex>
    </Box>
  );
}

export { OrderForm };
