import * as React from "react";

import {
  Box,
  BoxProps,
  HStack,
  Icon,
  IconButton,
  Text,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";

import { Action } from "icons";
import { AuditCommentsSharedSubsectionIndicator } from "../audit-comments";
import { ChartSubsectionInteractionsModal } from "./ChartSubsectionInteractionsModal";
import { Interaction } from "types";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { usePermissions } from "contexts";

type SharedChartSubsectionHeaderProps = {
  chartCode: string;
  encounterId: string;
  sectionCode: string;
  subsectionCode: string;
  subsectionLabel?: string;
  showNotesBtn?: boolean;
  showAuditsBtn?: boolean;
  subsectionNote?: string;
  endButton?: React.ReactNode;
  subsectionId?: string;
} & BoxProps;

const BANNED_CHARTS_FROM_TRACKING = [
  "actions",
  "order-form",
  "iv-and-medications",
];

export function SharedChartSubsectionHeader(
  props: SharedChartSubsectionHeaderProps
) {
  const {
    chartCode,
    encounterId,
    sectionCode,
    subsectionCode,
    subsectionLabel = "",
    showAuditsBtn = true,
    children,
    subsectionNote,
    endButton,
    subsectionId,
    ...boxProps
  } = props;

  const { scope } = usePermissions();
  const location = useLocation();
  const fromAudits = location.pathname.includes("audits");

  /**
   * The following variable will determine if the tracking will be available for the chart.
   * Some charts already have the tracking available, so we don't need to track them again.
   */
  const canTrackInteractions = React.useMemo(() => {
    return !BANNED_CHARTS_FROM_TRACKING.some((value) =>
      location.pathname.split("/").includes(value)
    );
  }, [location.pathname]);

  const { getValues } = useFormContext();

  const values = getValues();

  const chartInteractions = values?.[subsectionId || subsectionCode]
    ?.interactions as Interaction[];

  const chartInteractionsDisclose = useDisclosure();

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="0 1.25rem"
        {...boxProps}
      >
        <Box
          display="inline-flex"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
        >
          <HStack spacing="20px">
            <chakra.h2 fontSize="1.125rem" fontWeight="600">
              {children}
            </chakra.h2>
            {showAuditsBtn && fromAudits && (
              <AuditCommentsSharedSubsectionIndicator
                chartCode={chartCode}
                encounterId={encounterId}
                sectionCode={sectionCode}
                subsectionCode={subsectionId || subsectionCode}
                subsectionLabel={subsectionLabel}
                isFromSideBar={false}
              />
            )}
          </HStack>
          {subsectionNote && (
            <Text
              fontSize="15px"
              fontWeight="400"
              color="#9A9EA7"
              padding="0 1.25rem"
            >
              {subsectionNote}
            </Text>
          )}
        </Box>

        {!fromAudits &&
          canTrackInteractions &&
          scope("chartinteractions").isAccessible && (
            <IconButton
              aria-label="chart-subsection-interaction-btn"
              variant="icon"
              size="lg"
              icon={
                <Icon
                  as={Action}
                  color={!!chartInteractions ? "blue" : "gray.700"}
                  sx={{ h: "55px", w: "55px" }}
                />
              }
              onClick={chartInteractionsDisclose.onOpen}
            />
          )}
        {endButton}
      </Box>

      {!fromAudits &&
        canTrackInteractions &&
        scope("chartinteractions").isAccessible && (
          <ChartSubsectionInteractionsModal
            isOpen={chartInteractionsDisclose.isOpen}
            onClose={chartInteractionsDisclose.onClose}
            label={children}
            interactions={chartInteractions || []}
          />
        )}
    </>
  );
}

export type { SharedChartSubsectionHeaderProps };
