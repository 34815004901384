import { Box, BoxProps, Grid } from "@chakra-ui/react";
import { OrderFormElementType } from "enums";
import {
  UseUpdateOrderFormFavoritesDto,
  useUpdateOrderFormFavorites,
} from "modules/charts-shared/api";
import { UseOrderFormFavoritesResponse } from "modules/charts-shared/api/queries/useOrderFormFavorites";
import { useOrderListElements } from "modules/order-list/api";
import { OrderElementResponse } from "modules/order-list/types";
import { useMemo, useState } from "react";
import { OrderFormItemCard } from "./OrderFormItemCard";
import { OrderFormSearchBar } from "./OrderFormSearchBar";

type OrderFormListProps = {
  onAddItem: (item: OrderElementResponse) => void;
  type: OrderFormElementType;
  favorites: UseOrderFormFavoritesResponse[];
} & BoxProps;

function OrderFormList({
  onAddItem,
  type,
  favorites,
  ...rest
}: OrderFormListProps) {
  const { data } = useOrderListElements(type);
  const { mutateAsync } = useUpdateOrderFormFavorites();

  const [search, setSearch] = useState("");
  const [myFavorites, setMyFavorites] = useState(false);
  const [facilityFavorites, setFacilityFavorites] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const toggleFacilityFavorites = () => {
    setFacilityFavorites((prev) => !prev);
  };

  const toggleMyFavorites = () => {
    setMyFavorites((prev) => !prev);
  };

  const filteredItems = useMemo(() => {
    if (!data || !favorites) return [];
    return data.data.filter(
      (item) =>
        ((!facilityFavorites && !myFavorites) ||
          (facilityFavorites && item.favorite) ||
          (myFavorites &&
            favorites.find((fav) => fav.orderFormElementId === item.id))) &&
        (!search ||
          item.description.toLowerCase().includes(search.toLowerCase()))
    );
  }, [search, data, facilityFavorites, myFavorites, favorites]);

  const addToFavorites = async (ids: string[]) => {
    const updatingFavorites: UseUpdateOrderFormFavoritesDto[] = ids.map(
      (id) => {
        const existingFavorite = favorites.find(
          (fav) => fav.orderFormElementId === id
        );
        if (existingFavorite) {
          return { ...existingFavorite, favorite: !existingFavorite.favorite };
        }
        return {
          favorite: true,
          orderFormElementId: id,
        };
      }
    );
    await mutateAsync(updatingFavorites);
    setSelectedIds([]);
  };

  const handleAddFavorite = (id: string) => {
    const newIds = [...selectedIds, id];
    setSelectedIds(newIds);

    if (timer) {
      clearTimeout(timer);
    }

    setTimer(
      setTimeout(() => {
        addToFavorites(newIds);
      }, 2000)
    );
  };

  return (
    <Box>
      <OrderFormSearchBar
        search={search}
        onChangeSearch={(newSearch) => setSearch(newSearch)}
        toggleFacilityFavorites={toggleFacilityFavorites}
        toggleMyFavorites={toggleMyFavorites}
        facilityFavorites={facilityFavorites}
        myFavorites={myFavorites}
      />
      <Grid
        gridTemplateColumns="repeat(2, 1fr)"
        justifyItems="flex-start"
        rowGap="10px"
        columnGap="20px"
        {...rest}
      >
        {filteredItems.map((item) => (
          <OrderFormItemCard
            key={item.id}
            item={item}
            onClick={() => onAddItem(item)}
            isMyFavorite={
              !!favorites.find((fav) => fav.orderFormElementId === item.id)
                ?.favorite
            }
            onFavorite={() => handleAddFavorite(item.id)}
            isLoading={selectedIds.includes(item.id)}
          />
        ))}
      </Grid>
    </Box>
  );
}

export { OrderFormList };
