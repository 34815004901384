import {
  Box,
  Divider,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { EmrLock } from "@medstonetech/slate-icons";
import { OrderFormElementRoute } from "enums";
import {
  UseUpdateCommonDoseFavoritesDto,
  useUpdateCommonDoseFavorites,
} from "modules/charts-shared/api/mutations/useUpdateFavoriteCommonDoses";
import { useDoseFavorites } from "modules/charts-shared/api/queries/useDoseFavorites";
import { calculateMedicationAmount } from "modules/charts-shared/utils";
import { OrderElementResponse } from "modules/order-list/types";
import { Fragment, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { Button, Card, Checkbox, Label, Select } from "shared";
import { getOptions, valueToOption } from "utils/enum-mapper";
import { MedicationOrderForm } from "./AddMedicationOrderModal";

type FavoriteDosesProps = {
  item: OrderElementResponse;
  showCalculation: () => void;
};

const fieldLabelStyle = {
  mt: "10px",
  fontSize: "11pt",
};

function FavoriteDoses({ item, showCalculation }: FavoriteDosesProps) {
  const { setValue, register, watch, getValues } =
    useFormContext<MedicationOrderForm>();

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const { data: favoriteDoses } = useDoseFavorites(item.id);
  const { mutateAsync: updateCommonDoseFavorites } =
    useUpdateCommonDoseFavorites();

  const getConcentration = (concentrationId?: string) => {
    if (!concentrationId) return "";
    const concentration = item.orderFormElementConcentrations.find(
      (c) => c.id === concentrationId
    );
    if (concentration) {
      return ` (${concentration.doseAmount} ${concentration.doseUnit} / ${concentration.volumeAmount} ${concentration.volumeUnit})`;
    }
    return "";
  };

  const handleCalculate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = e;
    const { concentration, doseAmount, doseUnit, weight } = getValues();
    if (checked && concentration && doseAmount && doseUnit && weight) {
      const amount = calculateMedicationAmount(
        concentration,
        doseAmount,
        doseUnit,
        weight
      );
      setValue("amount", amount);
    } else {
      setValue("amount", null);
    }
  };

  const handleChangeDose = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked, value: doseId },
    } = e;
    const dose = item.orderFormElementCommonDoses.find((c) => c.id === doseId);
    if (checked && dose) {
      setValue("doseAmount", dose.doseAmount);
      setValue("doseUnit", dose.doseUnit);
      setValue("doseId", dose.id);

      const concentration = item.orderFormElementConcentrations.find(
        (c) => c.id === dose.orderFormEelementConcentrationId
      );
      if (concentration) {
        setValue("concentration", concentration);
      }
    } else {
      setValue("doseId", undefined);
      setValue("concentration", {});
    }
  };

  const favoriteCommonDoses = useMemo(() => {
    if (favoriteDoses) {
      return favoriteDoses.data?.favoriteOrderFormElementCommonDoses;
    }
    return [];
  }, [favoriteDoses]);

  const addToFavorites = async (ids: string[]) => {
    const updatingFavorites: UseUpdateCommonDoseFavoritesDto[] = [
      ...new Set(ids),
    ].map((id) => {
      const existingFavorite = favoriteCommonDoses.find(
        (fav) => fav.orderFormElementCommonDoseId === id
      );
      if (existingFavorite) {
        return { ...existingFavorite, favorite: !existingFavorite.favorite };
      }
      return {
        favorite: true,
        orderFormElementCommonDoseId: id,
      };
    });
    await updateCommonDoseFavorites(updatingFavorites);
    setSelectedIds([]);
  };

  const handleAddFavorite = (id: string) => {
    const newIds = [...selectedIds, id];
    setSelectedIds(newIds);

    if (timer) {
      clearTimeout(timer);
    }

    setTimer(
      setTimeout(() => {
        addToFavorites(newIds);
      }, 2000)
    );
  };

  return (
    <Box flex={1}>
      <Label {...fieldLabelStyle}>Medication Name *</Label>
      <InputGroup>
        <Input readOnly value={item?.description} />
        <InputRightElement>
          <Icon as={EmrLock} w="32px" h="32px" size="lg" color="gray.450" />
        </InputRightElement>
      </InputGroup>
      <Label {...fieldLabelStyle}>My Favorite Doses</Label>
      <Card variant="basic" minH="40px" alignItems="center" display="flex">
        <Text flex={1} textAlign="center">
          None
        </Text>
      </Card>
      <Label {...fieldLabelStyle}>Common Doses</Label>
      <Card variant="basic" p="15px" minH="40px">
        {item.orderFormElementCommonDoses?.length > 0 ? (
          <Stack>
            {item.orderFormElementCommonDoses?.map((cd, i, arr) => (
              <Fragment key={cd.id}>
                <Flex alignItems="center">
                  <Checkbox
                    isChecked={watch("doseId") === cd.id}
                    value={cd.id}
                    onChange={handleChangeDose}
                    sx={{ flex: 1 }}
                  >
                    {cd.doseAmount} {cd.doseUnit}
                    {getConcentration(cd.orderFormEelementConcentrationId)}
                  </Checkbox>
                  <IconButton
                    icon={
                      favoriteCommonDoses.find(
                        (fcd) => fcd.orderFormElementCommonDoseId === cd.id
                      )?.favorite ? (
                        <Icon as={MdFavorite} fill="orange" />
                      ) : (
                        <Icon as={MdFavoriteBorder} />
                      )
                    }
                    onClick={() => handleAddFavorite(cd.id)}
                    aria-label="favorite"
                    variant="icon"
                  />
                </Flex>
                {i < arr.length - 1 && <Divider />}
              </Fragment>
            ))}
          </Stack>
        ) : (
          <Text flex={1} textAlign="center">
            None
          </Text>
        )}
      </Card>
      {watch("concentration") && (
        <>
          <Label {...fieldLabelStyle}>Weight(kg)</Label>
          <Flex columnGap="10px">
            <Input type="number" step=".01" flex={1} {...register("weight")} />
            <Checkbox
              isDisabled={!Boolean(watch("weight"))}
              onChange={handleCalculate}
              flex={1}
            >
              Calculate from weight
            </Checkbox>
          </Flex>
        </>
      )}
      {watch("amount") && (
        <>
          <Label {...fieldLabelStyle}>Amount</Label>
          <Flex>
            <InputGroup flex={1}>
              <Input readOnly value={watch("amount") || 0} />
              <InputRightElement>mL</InputRightElement>
            </InputGroup>
            <Button
              flex={1}
              variant="label"
              color="blue"
              onClick={showCalculation}
            >
              Show Calculation
            </Button>
          </Flex>
        </>
      )}
      <Label {...fieldLabelStyle}>Route *</Label>
      <Select
        items={getOptions("orderFormElementRoute")}
        defaultSelectedItem={valueToOption(
          "orderFormElementRoute",
          getValues("route") as OrderFormElementRoute
        )}
        labelAccessor="label"
        valueAccessor="value"
        showBlank={false}
        onSelectedItemChange={(changes) =>
          setValue("route", changes.selectedItem?.value || "")
        }
      />
      <Label {...fieldLabelStyle}>Administration Time *</Label>
      <Input type="text" {...register("administrationTime")} />
      <Label {...fieldLabelStyle}>Notes</Label>
      <Input {...register("notes")} type="text" />
    </Box>
  );
}

export { FavoriteDoses };
