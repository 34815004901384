import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

type ReceptionCounterResponse = {
  allCurrentPatient: number;
  lobby: number;
  inProcess: number;
  observation: number;
  transfer: number;
  recentlyDischarged: number;
  receptionAudit: number;
};

function useReceptionCounters() {
  return useAxiosQuery(["receptionCounters"], () => {
    return axiosPatientInstance.get<ReceptionCounterResponse>(
      "v1/Reception/counters"
    );
  });
}

export { useReceptionCounters };
export type { ReceptionCounterResponse };
