import {
  Box,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from "@chakra-ui/react";
import { EmrSignature } from "@medstonetech/slate-icons";
import { useToast } from "hooks";
import { Clear } from "icons";
import React from "react";
import { Button } from "shared";
import SignaturePad from "signature_pad";

type SignatureModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSign: (signature: string) => void;
  isLoading?: boolean;
};

const SignatureModal = (props: SignatureModalProps) => {
  const toast = useToast();
  const { isOpen, onClose, onSign, isLoading } = props;
  const signaturePad = React.useRef<SignaturePad>();

  const resizeCanvas = (signatureCanvas: HTMLCanvasElement) => {
    if (signatureCanvas) {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      signatureCanvas.setAttribute("width", "800");
      signatureCanvas.setAttribute("height", "125");
      signatureCanvas.getContext("2d")?.scale(ratio, ratio);
      signaturePad.current?.clear();
    }
  };

  const onSignatureDone = () => {
    if (!signaturePad.current?.isEmpty()) {
      const data = signaturePad.current?.toDataURL() || "";
      onSign(data);
    } else {
      toast({
        status: "error",
        description:
          "Please sign your name with your finger or the stylus pen.",
      });
    }
  };

  const canvasReference = (signatureCanvas: HTMLCanvasElement) => {
    if (signatureCanvas) {
      signaturePad.current = new SignaturePad(signatureCanvas);
      resizeCanvas(signatureCanvas);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalContent borderRadius="5px" minWidth="860px" minHeight="360px">
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          bgColor="gray.200"
          borderTopRadius="5px"
        >
          <Button variant="label" color="blue" onClick={onClose}>
            Cancel
          </Button>
          <Box display="flex" alignContent="center" gap="0.5rem">
            <Icon as={EmrSignature} fontSize="1.25rem" color="black" />
            <Text fontSize="15px">Add Signature</Text>
          </Box>

          <Button
            variant="label"
            color="blue"
            onClick={onSignatureDone}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Done
          </Button>
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column">
          <Box flex="1" pt={8}>
            <canvas
              style={{
                width: "100%",
                height: "auto",
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23DFDFDFFF' stroke-width='3' stroke-dasharray='15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                borderRadius: "10px",
                backgroundColor: "#F8F8F8",
              }}
              ref={canvasReference}
              id="signature-canvas"
            />
          </Box>
          <Text textAlign="center" color="gray.750" fontSize="15px">
            Sign your name here with your finger or the stylus pen.
          </Text>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="center">
          <Button
            leftIcon={<Icon as={Clear} color="blue" />}
            variant="ghost"
            color="blue"
            fontSize="17px"
            onClick={() => {
              signaturePad.current?.clear();
            }}
          >
            Clear
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { SignatureModal as default };
