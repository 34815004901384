import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  UseDisclosureReturn,
  ModalFooter,
  Flex,
  IconButton,
  Box,
  Button,
  Icon,
} from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import { MdCloseFullscreen, MdOpenInFull } from "react-icons/md";

type FullScreenModalModalProps = {
  modalTitle: string;
  modalBody: ReactNode;
  modalFooter?: ReactNode;
} & Pick<UseDisclosureReturn, "isOpen" | "onClose">;

/**
 * Open modal with fullscreen functionality and close button functionality
 * pass the momdal content as modalBody and the modal title
 * @param props
 * @returns
 */
export function FullScreenModal(props: FullScreenModalModalProps) {
  const { isOpen, modalTitle, modalBody, modalFooter, onClose } = props;
  const [fullSize, setFullSize] = useState(false);
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      size={fullSize ? "full" : "3xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bg={"gray.50"}>
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <IconButton
              size="iconSm"
              variant="icon"
              aria-label={"full screen"}
              icon={
                <Icon
                  as={fullSize ? MdCloseFullscreen : MdOpenInFull}
                  color="blue"
                />
              }
              onClick={() => setFullSize(!fullSize)}
            />
            <Box>{modalTitle}</Box>
            <Button variant="action" borderRadius="10px" onClick={onClose}>
              Close
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody>{modalBody}</ModalBody>
        {modalFooter && <ModalFooter>{modalFooter}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
}
