import { SignatureType, UserSignatureType } from "enums";
import { UserType } from "modules/identity";

function getFormSignBySignatureType(userSignatureType: UserSignatureType) {
  switch (userSignatureType) {
    case "ActingProvider":
      return "actingProviderSignature";
    case "Nurse":
      return "nurseSignature";
    case "Scribe":
      return "scribeSignature";
    case "Supervisor":
      return "supervisorSignature";
    default:
      return "nurseSignature";
  }
}

const getUserTypeBySignatureType = (type: SignatureType): UserType => {
  switch (type) {
    case "SupervisingProvider":
      return "SupervisingProvider";
    case "TreatingProvider":
      return "Provider";
    case "ChargeNurse":
      return "Nurse";
    case "Nurse":
      return "Nurse";
    case "Scribe":
      return "Scribe";
    case "HUC":
      return "HUC";
  }
};

export { getFormSignBySignatureType, getUserTypeBySignatureType };
