import * as React from "react";
import { Segment, SegmentOption } from "shared";
import {
  VitalsInputModal,
  VitalsInputModalKeypad,
  VitalsInputModalKeypadExtraKeys,
  VitalsInputModalValueBox,
  BaseVitalsInputModalProps,
} from "./VitalsInputModal";
import { Spo2Type } from "../../types/types";
import { Button } from "@chakra-ui/react";
import { Close } from "icons";

type Spo2VitalsInputModalProps = Omit<
  BaseVitalsInputModalProps,
  "title" | "onSave"
> & {
  onSave: (value: string, type: Spo2Type) => void;
  initialType?: Spo2Type;
};

function getTypeValue(index: number) {
  const records: Record<number, Spo2Type> = {
    0: "ra",
    1: "nc",
    2: "m",
    3: "hf",
  };

  return records[index] || "ra";
}

function getTypeIndex(type: Spo2Type) {
  const records: Record<Spo2Type, number> = {
    ra: 0,
    nc: 1,
    m: 2,
    hf: 3,
  };

  return records[type] || 0;
}

function Spo2VitalsInputModal(props: Spo2VitalsInputModalProps) {
  const { onSave, initialValue, initialType, ...vitalsInputModalProps } = props;
  const { onClose } = vitalsInputModalProps;
  const [value, setValue] = React.useState("");
  const [type, setType] = React.useState(0);
  const extraKeys = React.useMemo<VitalsInputModalKeypadExtraKeys>(
    () => [
      { label: "L", onClick: () => setValue((prev) => `${prev}L`) },
      { label: "/", onClick: () => setValue((prev) => `${prev}/`) },
      { label: "min", onClick: () => setValue((prev) => `${prev}min`) },
    ],
    []
  );

  React.useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
    if (initialType) {
      setType(getTypeIndex(initialType));
    }
  }, [initialValue, initialType]);

  return (
    <VitalsInputModal title="SpO2" {...vitalsInputModalProps}>
      <VitalsInputModalValueBox
        selector={
          <Segment isFitted onChange={setType} index={type}>
            <SegmentOption>RA</SegmentOption>
            <SegmentOption>NC</SegmentOption>
            <SegmentOption>M</SegmentOption>
            <SegmentOption>HF</SegmentOption>
          </Segment>
        }
      >
        {value}
      </VitalsInputModalValueBox>
      <VitalsInputModalKeypad
        onClickKey={(x) => setValue((prev) => `${prev}${x}`)}
        onSave={() => {
          onSave(value, getTypeValue(type));
          onClose();
        }}
        onDelete={() => {
          if (value[value.length - 1] === "n") {
            setValue((prev) => prev.slice(0, prev.length - 3));
          } else {
            setValue((prev) => prev.slice(0, prev.length - 1));
          }
        }}
        extraKeys={extraKeys}
      />
      <Button
        onClick={onClose}
        variant="solid"
        m={0}
        minW="unset"
        backgroundColor="gray.350"
        w="30px"
        h="30px"
        position="absolute"
        top={3}
        right={3}
        maxH="unset"
        colorScheme="gray"
      >
        <Close width="10px" height="10px" color="gray.600" />
      </Button>
    </VitalsInputModal>
  );
}

export { Spo2VitalsInputModal };
