import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type AssignBedRequest = {
  bedId: string;
  bedCode: string;
  encounterId: string;
};

function useAssignBed(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["assignBed"],
    (input: AssignBedRequest) =>
      axiosIdentityInstance.put(`/v1/bed/assign-encounter`, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("rooms");
        queryClient.invalidateQueries("roomsDetails");
        queryClient.invalidateQueries("roomCounters");
        queryClient.invalidateQueries("inProcessEncounterList");
        queryClient.invalidateQueries(["patientDetails", { encounterId }]);
      },
    }
  );
}

export { useAssignBed };
export type { AssignBedRequest };
