import * as React from "react";
import {
  Flex,
  useMultiStyleConfig,
  chakra,
  StylesProvider,
  useStyles,
  Box,
  LinkProps as CkLinkProps,
} from "@chakra-ui/react";
import { Link, LinkProps, useMatch } from "react-router-dom";
import { contextErrors } from "messages";

type NavigationButtonProps = Omit<LinkProps, "to"> &
  CkLinkProps & { to: string; disabled?: boolean };

type NavigationButtonContentProps = {
  label: string;
  labelIcon: React.ReactNode;
};

type NavigationButtonContextValue = {
  match: boolean;
};

const NavigationButtonContext =
  React.createContext<NavigationButtonContextValue>({ match: false });

function useNavigationButtonContext(child: string) {
  const context = React.useContext(NavigationButtonContext);

  if (!context) {
    throw new Error(contextErrors.missingParent(child, "NavigationButton"));
  }

  return context;
}

const NavigationButtonContent: React.FC<NavigationButtonContentProps> = (
  props
) => {
  const { label, labelIcon } = props;
  const styles = useStyles();
  const { match } = useNavigationButtonContext("NavigationButtonContent");

  return (
    <Flex __css={styles.content}>
      {labelIcon && (
        <chakra.span display="flex">
          {React.isValidElement(labelIcon) &&
            React.cloneElement(labelIcon, {
              __css: styles.labelicon,
              "aria-selected": match,
            })}
        </chakra.span>
      )}
      <chakra.p title={label} __css={styles.label} aria-selected={match}>
        {label}
      </chakra.p>
    </Flex>
  );
};

type NavigationButtonIndicatorProps = {
  children?: React.ReactNode;
};

function NavigationButtonIndicator({
  children,
}: NavigationButtonIndicatorProps) {
  const styles = useStyles();

  return <chakra.span __css={styles.indicator}>{children}</chakra.span>;
}

function NavigationButton(props: NavigationButtonProps) {
  const { children, colorScheme, disabled, ...rest } = props;
  const match = useMatch({ path: rest.to, end: false });
  const styles = useMultiStyleConfig("NavigationButton", {
    ...rest,
    colorScheme,
  });
  const contextValue: NavigationButtonContextValue = React.useMemo(
    () => ({ match: !!match }),
    [match]
  );

  return (
    <>
      {disabled ? (
        <Box __css={styles.container} aria-selected={!!match}>
          <NavigationButtonContext.Provider value={contextValue}>
            <StylesProvider value={styles}>{children}</StylesProvider>
          </NavigationButtonContext.Provider>
        </Box>
      ) : (
        <chakra.a as={Link} __css={styles.root} role="link" {...rest}>
          <Box __css={styles.container} aria-selected={!!match}>
            <NavigationButtonContext.Provider value={contextValue}>
              <StylesProvider value={styles}>{children}</StylesProvider>
            </NavigationButtonContext.Provider>
          </Box>
        </chakra.a>
      )}
    </>
  );
}

export type { NavigationButtonProps, NavigationButtonContentProps };
export { NavigationButton, NavigationButtonContent, NavigationButtonIndicator };
