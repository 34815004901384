import { axiosPatientInstance } from "config";
import { EncounterStatus } from "enums";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useUpdateEncounterStatus(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateEncounterStatus", { encounterId }],
    (status: EncounterStatus) =>
      axiosPatientInstance.put(`v1/Encounter/${encounterId}/change-status`, {
        status,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterOverview", { encounterId }], {
          refetchInactive: true,
        });
        queryClient.invalidateQueries("encounters");
        queryClient.invalidateQueries("searchEncounters");
        queryClient.invalidateQueries("receptionCounters");
        queryClient.invalidateQueries(["inProcessCounters"]);
        queryClient.invalidateQueries([
          "patientInfoByEncounter",
          { encounterId },
        ]);
      },
    }
  );
}

export { useUpdateEncounterStatus };
