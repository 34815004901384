import { axiosOrderInstance } from "config/axios";
import { LabOrderType, OrderStatus } from "enums";
import { useAxiosQuery } from "hooks";
import { Contrast } from "modules/orders/types";
import { Cancellation } from "modules/orders/types/cancellation";

type MedicalStaffInfo = {
  id: string;
  name: string;
  role: string;
  pictureUrl?: string;
};

type UseEncounterOrderResponse = {
  orderId: string;
  status: OrderStatus;
  type: LabOrderType;
  description: string;
  orderedAt: Date | string | null;
  orderedBy: MedicalStaffInfo | null;
  approvedBy: MedicalStaffInfo | null;
  cancelledBy: MedicalStaffInfo | null;
  approvalTime: Date | string | null;
  cancellationTime: Date | string | null;
  contrast?: Contrast;
  cancellation?: Cancellation;
  fileId: string | null;
};

function useEncounterOrder(orderId: string) {
  return useAxiosQuery(["encounterOrder", { orderId }], () =>
    axiosOrderInstance.get<UseEncounterOrderResponse>(`v1/${orderId}/detail`)
  );
}

export type { UseEncounterOrderResponse };
export { useEncounterOrder };
