import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Hand = createIcon({
  path: (
    <path
      d="M16.8429 1.16703C15.8341 0.158242 14.4099 0 12.7187 0H5.25165C3.59011 0 2.16593 0.158242 1.15714 1.16703C0.148352 2.17582 0 3.59011 0 5.24176V12.7187C0 14.4099 0.148352 15.8143 1.15714 16.8231C2.16593 17.8319 3.59011 17.9901 5.27143 17.9901H12.7187C14.4099 17.9901 15.8341 17.8319 16.8429 16.8231C17.8516 15.8143 18 14.4099 18 12.7187V5.27143C18 3.58022 17.8516 2.16593 16.8429 1.16703ZM4.91538 10.9286V5.41978C4.91538 5.06374 5.21209 4.75714 5.58791 4.75714C5.93407 4.75714 6.24066 5.06374 6.24066 5.41978V8.88132C6.38901 8.79231 6.53736 8.69341 6.72527 8.61429V3.56044C6.72527 3.2044 7.02198 2.91758 7.37802 2.91758C7.73407 2.91758 8.04066 3.2044 8.04066 3.56044V8.18901C8.20879 8.15934 8.37692 8.13956 8.55494 8.11978V3.05604C8.55494 2.70989 8.84176 2.4033 9.20769 2.4033C9.57363 2.4033 9.86044 2.70989 9.86044 3.05604V8.17912C10.0286 8.22857 10.1967 8.28791 10.3549 8.32747V3.91648C10.3549 3.56044 10.6418 3.26374 11.0077 3.26374C11.3538 3.26374 11.6505 3.56044 11.6505 3.91648V10.1275C11.6505 10.3055 11.7198 10.3846 11.8484 10.3846C11.9374 10.3846 12.0264 10.3253 12.0956 10.1374L12.9264 8.3967C13.2033 7.83297 13.4209 7.5956 13.8165 7.5956C14.1626 7.5956 14.4099 7.82308 14.4099 8.14945C14.4099 8.42637 14.1824 9.04945 14.0242 9.55385C13.6582 10.7703 13.3121 11.8187 12.8571 12.6989C12.0363 14.3802 10.8 15.1022 9 15.1022C6.5967 15.1022 4.91538 13.5099 4.91538 10.9286Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 18",
});

export { Hand };
