import { axiosChartInstance } from "config";
import { OrderFormElementType } from "enums";
import { useAxiosQuery } from "hooks";
import { OrderElementResponse } from "modules/order-list/types/order-element-response";

function useOrderListElements(elementType: OrderFormElementType) {
  return useAxiosQuery(["orderListElements", elementType], () =>
    axiosChartInstance.get<OrderElementResponse[]>(
      `v1/order-form/elements/filter?formElementType=${elementType}`,
      {}
    )
  );
}
export { useOrderListElements };
