import { Box, Flex, Icon, IconButton, Text } from "@chakra-ui/react";
import { EmrClose } from "@medstonetech/slate-icons";
import { format } from "date-fns";
import { OrderItem } from "modules/charts-shared/types";
import { Card } from "shared";
import { toDisplay } from "utils/enum-mapper";

type OrderFormCartItemProps = {
  item: OrderItem;
  onDelete?: () => void;
};

function OrderFormCartItem({
  item: {
    orderElementType,
    description,
    date,
    location,
    orderElementCategory,
    administrationTime,
  },
  onDelete,
}: OrderFormCartItemProps) {
  return (
    <Card w="100%" p="10px 20px" variant="basic" height="120px">
      <Flex justifyContent="space-between" h="70px">
        <Box flex={1}>
          <Flex>
            <Text flex={1} color="gray">
              {orderElementType}
            </Text>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="flex-start"
              flex={2}
              columnGap="10px"
            >
              <Text color="gray">{format(date, "MM-dd-yyyy")}</Text>
              <Text color="gray">{format(date, "HH:mm a")}</Text>
            </Box>
          </Flex>
          <Box display="flex" flexDir="column" flex={1}>
            <Text fontWeight="500">{description}</Text>
            {orderElementCategory && (
              <Text color="gray">
                {toDisplay("orderFormElementCategory", orderElementCategory)}
              </Text>
            )}
          </Box>
          <Flex>
            <Text fontWeight="500">{!!location?.length ? location : ""}</Text>
          </Flex>
          <Flex>{administrationTime}</Flex>
        </Box>
        {onDelete && (
          <Flex alignItems="flex-start" justifyContent="center">
            <IconButton
              aria-label="Delete Item"
              variant="icon"
              color="red"
              onClick={onDelete}
            >
              <Icon as={EmrClose} />
            </IconButton>
          </Flex>
        )}
      </Flex>
    </Card>
  );
}

export { OrderFormCartItem };
