import * as React from "react";

import { Button, Loading, WarningDialog } from "shared";
import {
  ChartRouteBaseParams,
  SharedChartBaseProps,
} from "modules/charts-shared/types";
import {
  ChartRow,
  ChartRowElement,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSectionHeader,
  ChartSubsectionHeader,
  UserType,
} from "modules";
import { Flex, Icon, useDisclosure, useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";

import { ChartForm } from "modules/charts-shared-v2/components";
import { DispositionSignatureSubsection } from "./DispositionSignatureSubsection";
import { EmrSignature } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { FormProvider } from "react-hook-form";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useChartDispositionForm } from "modules/charts-shared/hooks/userChartDispositionForm";
import { usePermissions } from "contexts";
import { useUpdateChartApplicationStatus } from "modules/charts-shared/api/mutations/useUpdateChartApplicationStatus";
import { useUpdateEncounterStatus } from "modules/reception/api";
import { useUserRoles } from "contexts/UserRoles";

const SECTION_CODE = "SDIS";

type WarningAction = () => void;

function Disposition(props: SharedChartBaseProps) {
  const { chartCode, chartType, isReadOnly = false } = props;
  const navigate = useNavigate();
  const { roles } = useUserRoles();
  const { scope } = usePermissions();
  const toast = useToast();
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();

  const [warningDialogMessage, setWarningDialogMessage] = React.useState("");
  const [warningDialogAction, setWarningDialogAction] =
    React.useState<WarningAction>();

  const { getIsLoading, onSubmit, updateIsLoading, useFormContext } =
    useChartDispositionForm({
      sectionId: SECTION_CODE,
      sectionLabel: "Disposition",
      chartCode,
    });

  const {
    getValues,
    formState: { isDirty },
    control,
  } = useFormContext;

  const signatures = getValues("signatures");
  const encounterStatus = getValues("encounterStatus");
  const chartStatus = getValues("chartStatus");

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      await onSubmit();
      toast({ description: formMessages.updateSuccess("Disposition") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const isEmpty = () => {
    const values = getValues();
    return Object.keys(values).length === 0;
  };

  const {
    mutateAsync: updateEncounterStatus,
    isLoading: isLoadingEncounterStatusUpdate,
  } = useUpdateEncounterStatus(encounterId);

  const {
    mutateAsync: updateChartStatus,
    isLoading: isLoadingChartStatusUpdate,
  } = useUpdateChartApplicationStatus({
    encounterId,
    chartCode,
    sectionId: SECTION_CODE,
  });

  const { isOpen, onClose, onOpen } = useDisclosure();
  const handleChangeStatusToDischarge = async () => {
    try {
      await updateEncounterStatus("Discharged");
      toast({ description: formMessages.updateSuccess("Status") });
      navigate("/in-process");
    } catch (error) {
      toast({ status: "error", description: extractApiErrorMessage(error) });
    }
  };

  const handleChangeChartStatusToComplete = async () => {
    try {
      await updateChartStatus("Completed");
      toast({ description: formMessages.updateSuccess("ChartStatus") });

      onCloseWarningDialog();
    } catch (error) {
      toast({ status: "error", description: extractApiErrorMessage(error) });
    }
  };

  const onDischargePatientAction = () => {
    setWarningDialogMessage("Are you sure you want to Discharge this Patient?");
    setWarningDialogAction(() => handleChangeStatusToDischarge);
    onOpen();
  };

  const onCompleteChartAction = () => {
    setWarningDialogMessage(
      "Are you sure you want to mark this Chart as Complete?"
    );
    setWarningDialogAction(() => handleChangeChartStatusToComplete);
    onOpen();
  };

  const onCloseWarningDialog = () => {
    setWarningDialogMessage("");
    setWarningDialogAction(undefined);
    onClose();
  };

  const rolesAllowedToCompleteChart: UserType[] = [
    "Administrator",
    "SupervisingProvider",
    "Provider",
    "Nurse",
  ];

  if (getIsLoading || isEmpty()) {
    return <Loading />;
  }
  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrSignature} />}
          showActionsBtns
          isLoading={updateIsLoading}
          showNotesBtn={chartType === "Triage" ? false : undefined}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={SECTION_CODE}
        >
          Disposition {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q001"
              showNotesBtn={chartType === "Triage" ? false : undefined}
              showAuditsBtn={chartType === "Chart" || chartType === "Triage"}
            ></ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow display="flex">
                <ChartRowElement type="label" label="INTAKE" flex="1" />
                <ChartRowElement
                  type="input"
                  name="subsections.Q001.T001xSDISQ001A001"
                />
                <ChartRowElement type="label" label="OUTPUT" flex="1" />
                <ChartRowElement
                  type="input"
                  name="subsections.Q001.T001xSDISQ001A002"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  label="IV / lock discontinued:"
                  name="subsections.Q001.T001xSDISQ001A003"
                />
                <ChartRowElement
                  type="option"
                  label="cath intact"
                  name="subsections.Q001.T001xSDISQ001A004"
                />
                <ChartRowElement
                  type="option"
                  label="no swelling"
                  name="subsections.Q001.T001xSDISQ001A005"
                />
                <ChartRowElement
                  type="option"
                  label="no redness"
                  name="subsections.Q001.T001xSDISQ001A006"
                />
                <ChartRowElement
                  type="input"
                  name="subsections.Q001.T001xSDISQ001A007"
                />
              </ChartRow>
              <ChartRow display="flex">
                <ChartRowElement type="label" label="Time" />
                <ChartRowElement
                  type="input"
                  name="subsections.Q001.T001xSDISQ001A008"
                  flex="1"
                />
                <ChartRowElement type="label" label="INIT" />
                <ChartRowElement
                  type="input"
                  name="subsections.Q001.T001xSDISQ001A009"
                  flex="1"
                />
                <ChartRowElement
                  type="option"
                  label="IV / lock to floor:"
                  name="subsections.Q001.T001xSDISQ001A010"
                />
                <ChartRowElement type="label" label="amount remaining" />
                <ChartRowElement
                  type="input"
                  name="subsections.Q001.T001xSDISQ001A011"
                  flex="1"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q002"
              subsectionLabel="Property to"
              showNotesBtn={chartType === "Triage" ? false : undefined}
              showAuditsBtn={chartType === "Chart" || chartType === "Triage"}
            >
              Property to
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  label="patient"
                  name="subsections.Q002.T001xSDISQ002A001"
                />
                <ChartRowElement
                  type="option"
                  label="family"
                  name="subsections.Q002.T001xSDISQ002A002"
                />
                <ChartRowElement
                  type="option"
                  label="security"
                  name="subsections.Q002.T001xSDISQ002A003"
                />
                <ChartRowElement
                  type="option"
                  label="safe"
                  name="subsections.Q002.T001xSDISQ002A004"
                />
                <ChartRowElement
                  type="option"
                  label="see patient belongings list"
                  name="subsections.Q002.T001xSDISQ002A005"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q003"
              subsectionLabel="Discharge Vitals"
              showNotesBtn={chartType === "Triage" ? false : undefined}
              showAuditsBtn={chartType === "Chart" || chartType === "Triage"}
            >
              Discharge Vitals
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow display="flex">
                <ChartRowElement type="label" label="BP" />
                <ChartRowElement
                  type="input"
                  name="subsections.Q003.T001xSDISQ003A001"
                  flex="1"
                />
                <ChartRowElement type="label" label="P" />
                <ChartRowElement
                  type="input"
                  name="subsections.Q003.T001xSDISQ003A002"
                  flex="1"
                />
                <ChartRowElement type="label" label="RR" />
                <ChartRowElement
                  type="input"
                  name="subsections.Q003.T001xSDISQ003A003"
                  flex="1"
                />
                <ChartRowElement type="label" label="T" />
                <ChartRowElement
                  type="input"
                  name="subsections.Q003.T001xSDISQ003A004"
                  flex="1"
                />
                <ChartRowElement type="label" label="° F" />
              </ChartRow>
              <ChartRow display="flex">
                <ChartRowElement type="label" label="pain level" />
                <ChartRowElement
                  type="input"
                  name="subsections.Q003.T001xSDISQ003A005"
                  size="sm"
                />
                <ChartRowElement type="label" label="/10" />
                <ChartRowElement type="label" label="SpO2" />
                <ChartRowElement
                  type="input"
                  name="subsections.Q003.T001xSDISQ003A006"
                  flex="1"
                />
                <ChartRowElement type="label" label="GCS" />
                <ChartRowElement
                  type="input"
                  name="subsections.Q003.T001xSDISQ003A007"
                  flex="1"
                />
                <ChartRowElement type="label" label="Time" />
                <ChartRowElement
                  type="input"
                  name="subsections.Q003.T001xSDISQ003A008"
                  flex="1"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q004"
              subsectionLabel="Condition"
              showNotesBtn={chartType === "Triage" ? false : undefined}
              showAuditsBtn={chartType === "Chart" || chartType === "Triage"}
            >
              Condition
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  label="unchanged"
                  name="subsections.Q004.T001xSDISQ004A001"
                />
                <ChartRowElement
                  type="option"
                  label="improved"
                  name="subsections.Q004.T001xSDISQ004A002"
                />
                <ChartRowElement
                  type="option"
                  label="stable"
                  name="subsections.Q004.T001xSDISQ004A003"
                />
                <ChartRowElement
                  type="option"
                  label="other"
                  name="subsections.Q004.T001xSDISQ004A004"
                />
                <ChartRowElement
                  type="input"
                  name="subsections.Q004.T001xSDISQ004A005"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q005"
              subsectionLabel="Disposition"
              showNotesBtn={chartType === "Triage" ? false : undefined}
              showAuditsBtn={chartType === "Chart" || chartType === "Triage"}
            >
              Disposition
            </ChartSubsectionHeader>
          }
          content={
            <>
              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="option"
                    label="discharged"
                    name="subsections.Q005.T001xSDISQ005A01"
                  />
                  <ChartRowElement
                    type="option"
                    label="home"
                    name="subsections.Q005.T001xSDISQ005A02"
                  />
                  <ChartRowElement
                    type="option"
                    label="nursing home"
                    name="subsections.Q005.T001xSDISQ005A03"
                  />
                  <ChartRowElement
                    type="option"
                    label="police"
                    name="subsections.Q005.T001xSDISQ005A04"
                  />
                  <ChartRowElement
                    type="option"
                    label="medical examiner"
                    name="subsections.Q005.T001xSDISQ005A05"
                  />
                  <ChartRowElement
                    type="option"
                    label="funeral home"
                    name="subsections.Q005.T001xSDISQ005A06"
                  />
                  <ChartRowElement
                    type="input"
                    name="subsections.Q005.T001xSDISQ005A07"
                  />
                </ChartRow>
                <ChartRow>
                  <ChartRowElement type="empty" width="100px" />
                  <ChartRowElement
                    type="option"
                    label="verbal instructions"
                    name="subsections.Q005.T001xSDISQ005A08"
                  />
                  <ChartRowElement
                    type="option"
                    label="written instructions"
                    name="subsections.Q005.T001xSDISQ005A09"
                  />
                  <ChartRowElement
                    type="option"
                    label="Rx given"
                    name="subsections.Q005.T001xSDISQ005A010"
                  />
                  <ChartRowElement
                    type="option"
                    label="one"
                    name="subsections.Q005.T001xSDISQ005A011"
                  />
                  <ChartRowElement
                    type="option"
                    label="multi"
                    name="subsections.Q005.T001xSDISQ005A012"
                  />
                  <ChartRowElement type="label" label="to:" />
                  <ChartRowElement
                    type="option"
                    label="parent"
                    name="subsections.Q005.T001xSDISQ005A013"
                  />
                  <ChartRowElement
                    type="input"
                    name="subsections.Q005.T001xSDISQ005A014"
                  />
                </ChartRow>
                <ChartRow>
                  <ChartRowElement type="empty" width="100px" />
                  <ChartRowElement
                    type="option"
                    label="verbalized understanding"
                    name="subsections.Q005.T001xSDISQ005A015"
                  />
                  <ChartRowElement
                    type="input"
                    name="subsections.Q005.T001xSDISQ005A016"
                  />
                </ChartRow>
                <ChartRow>
                  <ChartRowElement type="empty" width="100px" />
                  <ChartRowElement
                    type="option"
                    label="accompanied by"
                    name="subsections.Q005.T001xSDISQ005A017"
                  />
                  <ChartRowElement
                    type="option"
                    label="driver"
                    name="subsections.Q005.T001xSDISQ005A018"
                  />
                  <ChartRowElement
                    type="input"
                    name="subsections.Q005.T001xSDISQ005A019"
                  />
                </ChartRow>
                <ChartRow>
                  <ChartRowElement type="empty" width="100px" />
                  <ChartRowElement type="label" label="notified" />
                  <ChartRowElement
                    type="option"
                    label="family"
                    name="subsections.Q005.T001xSDISQ005A020"
                  />
                  <ChartRowElement
                    type="option"
                    label="police"
                    name="subsections.Q005.T001xSDISQ005A021"
                  />
                  <ChartRowElement
                    type="option"
                    label="medical examiner"
                    name="subsections.Q005.T001xSDISQ005A022"
                  />
                  <ChartRowElement
                    type="input"
                    name="subsections.Q005.T001xSDISQ005A023"
                  />
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="option"
                    label="admitted"
                    name="subsections.Q005.T001xSDISQ005A024"
                  />
                  <ChartRowElement
                    type="option"
                    label="transferred to"
                    name="subsections.Q005.T001xSDISQ005A025"
                  />
                  <ChartRowElement
                    type="input"
                    name="subsections.Q005.T001xSDISQ005A026"
                  />
                </ChartRow>
                <ChartRow>
                  <ChartRowElement type="empty" width="100px" />
                  <ChartRowElement type="label" label="report to:" />
                  <ChartRowElement
                    type="input"
                    name="subsections.Q005.T001xSDISQ005A027"
                  />
                  <ChartRowElement type="label" label="time:" />
                  <ChartRowElement
                    type="input"
                    name="subsections.Q005.T001xSDISQ005A028"
                  />
                </ChartRow>
                <ChartRow>
                  <ChartRowElement type="empty" width="100px" />
                  <ChartRowElement type="label" label="advance directive" />
                  <ChartRowElement
                    type="option"
                    label="unavailable"
                    name="subsections.Q005.T001xSDISQ005A029"
                  />
                  <ChartRowElement
                    type="option"
                    label="added to chart"
                    name="subsections.Q005.T001xSDISQ005A030"
                  />
                  <ChartRowElement
                    type="option"
                    label="discussed w/ pt family"
                    name="subsections.Q005.T001xSDISQ005A031"
                  />
                  <ChartRowElement
                    type="option"
                    label="instructed to bring copy"
                    name="subsections.Q005.T001xSDISQ005A032"
                  />
                  <ChartRowElement type="empty" />
                </ChartRow>
                <ChartRow>
                  <ChartRowElement type="empty" width="120px" />
                  <ChartRowElement
                    type="option"
                    label="information provided"
                    name="subsections.Q005.T001xSDISQ005A033"
                  />
                  <ChartRowElement
                    type="input"
                    name="subsections.Q005.T001xSDISQ005A034"
                  />
                </ChartRow>
                <ChartRow>
                  <ChartRowElement type="empty" width="100px" />
                  <ChartRowElement
                    type="option"
                    label="transfer documentation completed"
                    name="subsections.Q005.T001xSDISQ005A035"
                  />
                  <ChartRowElement
                    type="option"
                    label="see transfer of care template"
                    name="subsections.Q005.T001xSDISQ005A036"
                  />
                  <ChartRowElement type="empty" />
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="option"
                    label="left AMA"
                    name="subsections.Q005.T001xSDISQ005A037"
                  />
                  <ChartRowElement
                    type="option"
                    label="LWBS"
                    name="subsections.Q005.T001xSDISQ005A038"
                  />
                  <ChartRowElement
                    type="input"
                    name="subsections.Q005.T001xSDISQ005A039"
                  />
                </ChartRow>
                <ChartRow>
                  <ChartRowElement type="empty" width="100px" />
                  <ChartRowElement
                    type="option"
                    label="signed AMA sheet"
                    name="subsections.Q005.T001xSDISQ005A040"
                  />
                  <ChartRowElement
                    type="option"
                    label="refused"
                    name="subsections.Q005.T001xSDISQ005A041"
                  />
                  <ChartRowElement
                    type="input"
                    name="subsections.Q005.T001xSDISQ005A042"
                  />
                </ChartRow>
                <ChartRow>
                  <ChartRowElement type="empty" width="100px" />
                  <ChartRowElement
                    type="option"
                    label="physician notified:"
                    name="subsections.Q005.T001xSDISQ005A043"
                  />
                  <ChartRowElement
                    type="input"
                    name="subsections.Q005.T001xSDISQ005A044"
                  />
                </ChartRow>
                <ChartRow>
                  <ChartRowElement type="label" label="Mode:" />
                  <ChartRowElement
                    type="option"
                    label="walk"
                    name="subsections.Q005.T001xSDISQ005A045"
                  />
                  <ChartRowElement
                    type="option"
                    label="crutches"
                    name="subsections.Q005.T001xSDISQ005A046"
                  />
                  <ChartRowElement
                    type="option"
                    label="W/C"
                    name="subsections.Q005.T001xSDISQ005A047"
                  />
                  <ChartRowElement
                    type="option"
                    label="stretcher"
                    name="subsections.Q005.T001xSDISQ005A048"
                  />
                  <ChartRowElement
                    type="option"
                    label="ambulance"
                    name="subsections.Q005.T001xSDISQ005A049"
                  />
                  <ChartRowElement
                    type="input"
                    name="subsections.Q005.T001xSDISQ005A050"
                  />
                </ChartRow>
                <ChartRow>
                  <ChartRowElement type="label" label="Depart Date" />
                  <ChartRowElement
                    type="date"
                    name="subsections.Q005.T001xSDISQ005A051"
                    width="10rem"
                  />
                  <ChartRowElement type="label" label="Time" />
                  <ChartRowElement type="input" name="Q005.T001xSDISQ005A052" />
                </ChartRow>
              </ChartSubsectionContent>
            </>
          }
        />
        <DispositionSignatureSubsection
          signatures={signatures}
          chartCode={chartCode}
          encounterId={encounterId}
          sectionCode={SECTION_CODE}
          chartType={chartType}
          control={control}
        />

        <Flex justifyContent="center" width="100%" pt={8} pb={16} gap={30}>
          <Button
            variant="outlineSquared"
            borderRadius="25px"
            width="320px"
            isDisabled={
              signatures?.some((signature) => !signature.user?.signature) ||
              isLoadingEncounterStatusUpdate ||
              isLoadingChartStatusUpdate ||
              !scope("dischargepatients").isAccessible ||
              encounterStatus === "Discharged"
            }
            onClick={onDischargePatientAction}
            isLoading={isLoadingEncounterStatusUpdate}
          >
            Discharge Patient
          </Button>

          <Button
            variant="outlineSquared"
            borderRadius="25px"
            width="320px"
            isDisabled={
              !roles.some((role) =>
                rolesAllowedToCompleteChart.includes(role)
              ) ||
              isLoadingEncounterStatusUpdate ||
              isLoadingChartStatusUpdate ||
              chartStatus === "Completed"
            }
            onClick={onCompleteChartAction}
            isLoading={isLoadingChartStatusUpdate}
          >
            Chart Complete
          </Button>
          <WarningDialog
            isOpen={isOpen}
            onCancel={onCloseWarningDialog}
            title="Warning"
            mainText={warningDialogMessage}
            onClose={onCloseWarningDialog}
            onAction={() => warningDialogAction && warningDialogAction()}
            cancelLabel="No"
            actionLabel="Yes"
            blockScrollOnMount={false}
            cancelButtonProps={{ color: "red" }}
            actionButtonProps={{ color: "blue" }}
          />
        </Flex>
      </ChartForm>
    </FormProvider>
  );
}

export { Disposition };
