import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ProviderChartCode, TriageChartCode } from "enums";
import { OrderQueue } from "icons";
import { OrderItem } from "modules/charts-shared/types";
import { OrderFormCartItem } from "./OrderFormCartItem";
import { ReviewOrdersModal } from "./ReviewOrdersModal";

type OrderFormCartProps = {
  items: OrderItem[];
  onDelete: (id: string) => void;
  onClear: () => void;
  chartCode: TriageChartCode | ProviderChartCode;
};

function OrderFormCart({
  items,
  chartCode,
  onDelete,
  onClear,
}: OrderFormCartProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = () => {
    onClear();
    onClose();
  };

  return (
    <>
      <HStack alignItems="center">
        <Icon as={OrderQueue} color="gray.700" mr="15px" w="24px" h="24px" />
        <Text fontSize="1.125rem" fontWeight="bold">
          Order Queue
        </Text>
        <Box flex={1} />
        <Button
          variant="square"
          disabled={items.length === 0}
          onClick={onClear}
        >
          Clear
        </Button>
        <Button variant="square" disabled={items.length === 0} onClick={onOpen}>
          Review
        </Button>
      </HStack>
      <Divider width="110%" my="10px" ml="-15px" />
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent={items.length > 0 ? "flex-start" : "center"}
        rowGap="10px"
      >
        {items.length > 0 ? (
          items.map((item) => (
            <OrderFormCartItem item={item} onDelete={() => onDelete(item.id)} />
          ))
        ) : (
          <Text color="gray" fontSize="1.125rem" fontWeight="bold">
            Queue is empty
          </Text>
        )}
      </Box>
      <ReviewOrdersModal
        isOpen={isOpen}
        onClose={onClose}
        onSend={handleClose}
        chartCode={chartCode}
        items={items}
      />
    </>
  );
}
export { OrderFormCart };
