import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

type InProcessCounterResponse = {
  allCurrentPatient: number;
  lobby: number;
  inProcess: number;
  observation: number;
  transfer: number;
  recentlyDischarged: number;
  receptionAudit: number;
  providerOpenCharts: number;
};

function useInProcessCounters() {
  return useAxiosQuery(["inProcessCounters"], () => {
    return axiosPatientInstance.get<InProcessCounterResponse>(
      "v1/InProcess/counters"
    );
  });
}

export { useInProcessCounters };
export type { InProcessCounterResponse };
