import {
  Box,
  Button,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { EmrCheck } from "@medstonetech/slate-icons";
import { UseUserByScopeResponseItem, useUserByScope } from "api";
import { PersonalInfo } from "modules/in-process/components/in-process-patients/PersonalInfo";
import { useState } from "react";
import { DividerList, SearchBar } from "shared";

type AddTransferCareProviderModalProps = Pick<
  UseDisclosureReturn,
  "isOpen" | "onClose"
> & {
  progressNoteId: string;
  onSelectedProvider: (provider: UseUserByScopeResponseItem) => void;
};

function AddTransferCareProviderModal(
  props: AddTransferCareProviderModalProps
) {
  const { onClose, isOpen, onSelectedProvider } = props;

  const [search, setSearch] = useState("");
  const [selectedProvider, setSelectedProvider] =
    useState<UseUserByScopeResponseItem | null>(null);

  const { data } = useUserByScope("provider:adddelete", search);

  const handleClose = () => {
    setSelectedProvider(null);
    onClose();
  };

  const handleSelectProvider = () => {
    if (selectedProvider) {
      onSelectedProvider(selectedProvider);
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        maxW="unset"
        width="540px"
        containerProps={{ justifyContent: "flex-end" }}
        height="100%"
        margin="0"
        overflowY="auto"
        overflowX="hidden"
      >
        <ModalHeader
          display="flex"
          justifyContent="space-between"
          fontSize="1rem"
          padding="20px 16px 30px"
        >
          <Button variant="label" onClick={handleClose} color="blue">
            Cancel
          </Button>
          <Heading fontWeight="600" fontSize="1.0625rem">
            Providers
          </Heading>
          <Button
            variant="label"
            onClick={handleSelectProvider}
            color="blue"
            disabled={!selectedProvider}
          >
            Done
          </Button>
        </ModalHeader>
        <ModalBody
          padding="34px 15px 15px"
          display="flex"
          flexDirection="column"
          overflow="hidden"
        >
          <SearchBar
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <DividerList dividerLeftMargin="62px">
            {data?.data.map((item) => (
              <Box
                padding="15px 21px"
                key={item.id}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                _selected={{ bg: "blue", color: "white" }}
                onClick={() => setSelectedProvider(item)}
              >
                <PersonalInfo
                  name={item.fullName}
                  pictureUrl={item.pictureUrl}
                  description={item.teams}
                />
                {item.id === selectedProvider?.id && (
                  <Icon as={EmrCheck} fontSize="1rem" color="blue" />
                )}
              </Box>
            ))}
          </DividerList>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export { AddTransferCareProviderModal };
