import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { OrderItem } from "modules/charts-shared/types";
import { Button, ToolbarHeader } from "shared";
import { OrderFormCartItem } from "./OrderFormCartItem";
import { useCreateOrder } from "modules/orders/api";
import { extractApiErrorMessage } from "utils";
import { formMessages, genericErrors } from "messages";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { ProviderChartCode, TriageChartCode } from "enums";

type RevireOrdersModalProps = {
  isOpen: boolean;
  onClose: () => void;
  items: OrderItem[];
  onSend: () => void;
  chartCode: TriageChartCode | ProviderChartCode;
};

function ReviewOrdersModal({
  isOpen,
  onClose,
  items,
  onSend,
  chartCode,
}: RevireOrdersModalProps) {
  const { encounterId = "" } = useParams();
  const { mutate, isLoading, isSuccess, isError, error, reset } =
    useCreateOrder(encounterId, chartCode);
  const handleSendOrder = () => {
    mutate(items);
  };

  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      toast({
        status: "success",
        description: formMessages.createSuccess("Order"),
      });
      onSend();

      const mainRoute = window.location.hash.split("/")[1];
      const chartType = window.location.hash.split("/")[4];
      switch (mainRoute) {
        case "labs":
          navigate(`/labs/lab-orders/${encounterId}`);
          break;
        case "radiology":
          navigate(`/radiology/radiology-orders/${encounterId}`);
          break;
        case "audits":
        case "in-process":
          if (
            items.some((item) => item.orderElementType === "Medication") &&
            chartType === "triage"
          ) {
            navigate("./../iv-and-medications");
          } else {
            navigate("./../order-history");
          }
          break;
      }
    }
    if (isError) {
      toast({
        status: "error",
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
    if (isSuccess || isError) {
      reset();
    }
  }, [
    isSuccess,
    isError,
    error,
    toast,
    onSend,
    reset,
    navigate,
    encounterId,
    items,
  ]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bgColor="gray.200" height="90vh" width="540px" mb="0">
        <ModalHeader>
          <ToolbarHeader
            leftButtons={[
              <Button key="cancel-btn" onClick={onClose}>
                Cancel
              </Button>,
            ]}
            rightButtons={[
              <Button
                key="send-btn"
                onClick={handleSendOrder}
                isLoading={isLoading}
              >
                Send
              </Button>,
            ]}
            titleText="Review Orders"
          />
        </ModalHeader>
        <ModalBody>
          <Text align="center" mb="35px">
            Once sent, these orders will be started inmediately.
          </Text>
          <Flex direction="column" rowGap="10px">
            {items.map((item) => (
              <OrderFormCartItem item={item} />
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export { ReviewOrdersModal };
