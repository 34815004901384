import { axiosOrderInstance } from "config/axios";
import { useAxiosQuery } from "hooks";
import { PaginationPayload, PaginationResponse } from "types";
import { OrderHistoryItem } from "./useOrderHistory";

type OrderHistoryPaginationProps = PaginationPayload & {
  encounterId?: string;
  elementType?: string;
};

type OrderHistoryPaginationResponse = PaginationResponse<OrderHistoryItem>;

function useOrderHistoryPagination(
  props: OrderHistoryPaginationProps,
  options?: { enabled: boolean }
) {
  const { encounterId, page = 1, size = 10, elementType } = props;

  return useAxiosQuery(
    ["orderHistoryPagination", { encounterId, elementType }],
    () =>
      axiosOrderInstance.get<OrderHistoryPaginationResponse>(
        `v1/order-history/${encounterId}/all`,
        {
          params: {
            page,
            pageSize: size,
            elementType,
          },
        }
      ),
    { keepPreviousData: false, ...options }
  );
}

export { useOrderHistoryPagination };
