import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
  useToast,
} from "@chakra-ui/react";
import { EmrCheck } from "@medstonetech/slate-icons";
import { ProviderChartCode, SignatureType, TriageChartCode } from "enums";
import { useInputMenu } from "hooks";
import {
  DispositionForm,
  DispositionSignatureForm,
} from "modules/charts-shared/types";
import { getUserTypeBySignatureType } from "modules/charts-shared/utils";
import { useUsers, UseUsersResponseItem } from "modules/identity";
import { PersonalInfo } from "modules/in-process/components/in-process-patients/PersonalInfo";
import React from "react";
import { Control, useFieldArray } from "react-hook-form";
import { BackButton, DividerList, Loading, SearchBar } from "shared";
import { USERID_CLAIMS } from "system-constants";
import { debounce } from "throttle-debounce";
import { extractApiErrorMessage } from "utils";

type SelectUserSignatureTypeModalContentProps = Pick<
  SelectUserSignatureTypeModalProps,
  | "onClose"
  | "title"
  | "chartCode"
  | "chartType"
  | "encounterId"
  | "sectionCode"
  | "onCloseTypeModal"
  | "control"
> & { selectedType: SignatureType };

const idAccessor = (value: UseUsersResponseItem | null) => value?.id || "";

const SelectUserSignatureTypeModalContent = (
  props: SelectUserSignatureTypeModalContentProps
) => {
  const { title, selectedType, onClose, onCloseTypeModal, control } = props;
  const toast = useToast();
  const { user: currentUser } = useAuth0();

  const { insert } = useFieldArray({
    control,
    name: "signatures",
  });

  const [search, setSearch] = React.useState("");
  const debouncedSearch = debounce(1000, setSearch);

  const [userSelected, setUserSelected] =
    React.useState<UseUsersResponseItem | null>(null);

  const {
    data,
    isLoading: isUsersLoading,
    error: usersError,
  } = useUsers({
    userType: getUserTypeBySignatureType(selectedType),
    search,
  });

  React.useEffect(() => {
    if (usersError) {
      toast({ description: extractApiErrorMessage(usersError) });
    }
  }, [usersError, toast]);

  const handleContinue = () => {
    if (userSelected) {
      const signature: DispositionSignatureForm = {
        userId: userSelected.id,
        type: selectedType,
        createdBy: currentUser ? currentUser[USERID_CLAIMS] : "",
        user: {
          id: userSelected.id,
          fullName: userSelected.fullName,
          teams: userSelected.teams,
          pictureUrl: userSelected.pictureUrl,
          signature: userSelected.signature,
        },
      };
      insert(0, signature);
      onClose();
      onCloseTypeModal();
    }
  };

  const handleSelectItem = (user: UseUsersResponseItem | null) => {
    setUserSelected(user);
  };

  const { getMenuItemProps, getMenuProps, selectedItem } = useInputMenu({
    items: data?.data || [],
    defaultSelectedItem: null,
    idAccessor,
    onSelectedItemChange: handleSelectItem,
  });

  return (
    <>
      <ModalHeader
        display="flex"
        justifyContent="space-between"
        fontSize="1rem"
        padding="20px 16px 0"
      >
        <BackButton onClick={onClose}>Back</BackButton>
        <Heading fontWeight="600" fontSize="1.0625rem">
          {title}
        </Heading>
        <Button variant="label" color="blue" onClick={handleContinue}>
          Continue
        </Button>
      </ModalHeader>
      <ModalBody padding="34px 16px 16px">
        {isUsersLoading ? (
          <Loading />
        ) : (
          <>
            <Box margin="20px 0">
              <SearchBar
                placeholder="Search"
                height="36px"
                bg="gray.350"
                onChange={(e) => debouncedSearch(e.target.value)}
              />
            </Box>
            <Box
              borderRadius="10px"
              bg="white"
              overflow="hidden"
              outline="none"
              _focus={{
                boxShadow: "var(--chakra-shadows-outline)",
              }}
              {...getMenuProps()}
            >
              <DividerList dividerLeftMargin="62px">
                {data?.data.map((item, index) => (
                  <Box
                    key={item.id}
                    width="510px"
                    padding="8px 12px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    _selected={{ color: "blue" }}
                    _checked={{ color: "blue" }}
                    {...getMenuItemProps({ item, index })}
                  >
                    <PersonalInfo
                      name={item.fullName}
                      pictureUrl={item.pictureUrl}
                      description={item.teams}
                    />
                    {idAccessor(item) === selectedItem?.id && (
                      <Icon as={EmrCheck} fontSize="1rem" />
                    )}
                  </Box>
                ))}
              </DividerList>
            </Box>
          </>
        )}
      </ModalBody>
    </>
  );
};

type SelectUserSignatureTypeModalProps = Pick<
  UseDisclosureReturn,
  "isOpen" | "onClose"
> & {
  onCloseTypeModal: () => void;
  title: string;
  selectedType?: SignatureType;
  chartCode: TriageChartCode | ProviderChartCode;
  encounterId: string;
  sectionCode: string;
  chartType: string;
  control: Control<DispositionForm>;
};

const SelectUserSignatureTypeModal = (
  props: SelectUserSignatureTypeModalProps
) => {
  const { title, isOpen, onClose, selectedType, ...rest } = props;

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent
        width="540px"
        maxW="unset"
        bg="gray.200"
        height="90%"
        margin="0"
        alignSelf="center"
      >
        {isOpen && selectedType && (
          <SelectUserSignatureTypeModalContent
            title={title}
            onClose={onClose}
            selectedType={selectedType}
            {...rest}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

export { SelectUserSignatureTypeModal };
