import * as React from "react";
import { chakra, FormLabel, FormLabelProps } from "@chakra-ui/react";

const formLabelOmittedProps = ["requiredIndicator"] as const;
type CkFormLabelProps = Omit<
  FormLabelProps,
  typeof formLabelOmittedProps[number]
>;

type LabelProps = CkFormLabelProps;

export function Label(props: LabelProps) {
  return (
    <FormLabel
      {...props}
      color="gray.650"
      ml="10px"
      mb="7px"
      whiteSpace="nowrap"
      requiredIndicator={<chakra.span color="red"> *</chakra.span>}
    />
  );
}
