import * as React from "react";

import {
  Box,
  BoxProps,
  HStack,
  Icon,
  IconButton,
  Text,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";

import { Action } from "icons";
import { AuditCommentsSubsectionIndicator } from "../audit-comments";
import { ChartSubsectionInteractionsModal } from "./ChartSubsectionInteractionsModal";
import { Interaction } from "types";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { usePermissions } from "contexts";

type ChartSubsectionHeaderProps = {
  chartId: string;
  encounterId: string;
  sectionId: string;
  subsectionId: string;
  subsectionLabel?: string;
  showNotesBtn?: boolean;
  showAuditsBtn?: boolean;
  subsectionNote?: string;
  subsectionOptions?: React.ReactElement;
  isRequired?: boolean;
} & BoxProps;

const BANNED_CHARTS_FROM_TRACKING = ["actions", "order-form"];

export function ChartSubsectionHeader(props: ChartSubsectionHeaderProps) {
  const {
    chartId,
    encounterId,
    sectionId,
    subsectionId,
    subsectionLabel = "",
    showAuditsBtn = true,
    children,
    subsectionNote,
    subsectionOptions,
    isRequired = true,
    ...boxProps
  } = props;

  const { getValues } = useFormContext();

  const values = getValues();

  const chartInteractions = values?.[subsectionId]
    ?.interactions as Interaction[];

  const chartInteractionsDisclose = useDisclosure();

  const { scope } = usePermissions();

  const isAuditor = scope("audits").isEditable;

  const location = useLocation();
  const fromAudits = location.pathname.includes("audits");

  /**
   * The following variable will determine if the tracking will be available for the chart.
   * Some charts already have the tracking available, so we don't need to track them again.
   */
  const canTrackInteractions = React.useMemo(() => {
    return !BANNED_CHARTS_FROM_TRACKING.some((value) =>
      location.pathname.split("/").includes(value)
    );
  }, [location.pathname]);

  const titleAccesory = React.useMemo(
    () =>
      ["SHPI", "SROS", "SPEX", "SCHC", "SASS"].includes(sectionId) && isRequired
        ? "*"
        : "",
    [sectionId, isRequired]
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="0 1.25rem"
        {...boxProps}
      >
        <Box
          display="inline-flex"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
        >
          <HStack spacing="20px">
            <chakra.h2 fontSize="1.125rem" fontWeight="600">
              {children} <chakra.span color="red">{titleAccesory}</chakra.span>
            </chakra.h2>

            {!!subsectionOptions && React.cloneElement(subsectionOptions)}
            {showAuditsBtn && fromAudits && isAuditor && (
              <AuditCommentsSubsectionIndicator
                encounterId={encounterId}
                chartCode={chartId}
                sectionCode={sectionId}
                subsectionCode={subsectionId}
                subsectionLabel={subsectionLabel}
                isFromSideBar={false}
              />
            )}
          </HStack>

          {subsectionNote && (
            <Text
              fontSize="15px"
              fontWeight="400"
              color="#9A9EA7"
              padding="0 1.25rem"
            >
              {subsectionNote}
            </Text>
          )}
        </Box>

        {!fromAudits &&
          canTrackInteractions &&
          scope("chartinteractions").isAccessible && (
            <Box
              display="inline-flex"
              justifyContent="space-between"
              alignItems="end"
              position="relative"
            >
              <IconButton
                aria-label="chart-subsection-interaction-btn"
                variant="icon"
                size="lg"
                icon={
                  <Icon
                    as={Action}
                    color={!!chartInteractions ? "blue" : "gray.700"}
                    sx={{ h: "55px", w: "55px" }}
                  />
                }
                onClick={chartInteractionsDisclose.onOpen}
              />
            </Box>
          )}
      </Box>

      {!fromAudits &&
        canTrackInteractions &&
        scope("chartinteractions").isAccessible && (
          <ChartSubsectionInteractionsModal
            isOpen={chartInteractionsDisclose.isOpen}
            onClose={chartInteractionsDisclose.onClose}
            label={children}
            interactions={chartInteractions || []}
          />
        )}
    </>
  );
}

export type { ChartSubsectionHeaderProps };
