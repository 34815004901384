import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { AllergyRx, MedicationRx } from "modules/charts-shared/types";
import { useQueryClient } from "react-query";

type UseUpdateEncounterAllergiesMedicationsRxProps = {
  encounterId: string;
};

type EncounterAllergiesRxPayload = {
  allergyRxList: AllergyRx[];
};

type EncounterMedicationsRxPayload = {
  medicationRxList: MedicationRx[];
};

type EncounterAllergiesMedicationsRxPayload = {
  allergyRxRequest: EncounterAllergiesRxPayload;
  medicationRxRequest: EncounterMedicationsRxPayload;
  nkda: boolean;
};

function useUpdateEncounterAllergiesMedicationsRx(
  params: UseUpdateEncounterAllergiesMedicationsRxProps
) {
  const { encounterId } = params;
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateEncounterAllergiesMedicationsRx", { encounterId }],
    (input: EncounterAllergiesMedicationsRxPayload) =>
      axiosPatientInstance.put(
        `v1/InProcess/${encounterId}/allergies-medications-rx`,
        input
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["patientInfoByEncounter", params]);

        queryClient.invalidateQueries([
          "getEncounterAllergiesMedicationsRx",
          params,
        ]);
      },
    }
  );
}

export type {
  UseUpdateEncounterAllergiesMedicationsRxProps,
  EncounterAllergiesMedicationsRxPayload,
};
export { useUpdateEncounterAllergiesMedicationsRx };
