import {
  Box,
  Icon,
  useControllableState,
  useDisclosure,
} from "@chakra-ui/react";
import {
  EmrBed,
  EmrPerson,
  SiStar,
  EmrTransportation,
  EmrInProcess,
  EmrRoomCard,
} from "@medstonetech/slate-icons";
import { useToast } from "hooks";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  BackButton,
  Button,
  NavigationButton,
  NavigationButtonContent,
  NavigationButtonIndicator,
  Sidebar,
  SidebarActions,
  SidebarBottom,
  SidebarContent,
  SidebarHeader,
  SidebarHeading,
  SidebarNavigation,
  SidebarUserView,
} from "shared";
import {
  InProcessOptionsRadio,
  InProcessOptionsRadioItem,
} from "../InProcessOptionsRadio";
import { InProcessSortOptions } from "modules/in-process/api/queries/useInProcessEncounters";
import { OpenCharts } from "icons";
import { useInProcessCounters } from "modules/in-process/api";
import RoomManagement from "modules/room-management";
import { FaqButton } from "modules/faq/components";

type InProcessSidebarProps = {
  didMainFilterChange?: (valueFilter: InProcessSortOptions) => void;
  mainFilter?: InProcessSortOptions;
};

const RoomManagementButton = () => {
  const roomManagementDisclosure = useDisclosure();

  return (
    <>
      <Button
        variant="outlineSquared"
        width="100%"
        mt="53px"
        onClick={roomManagementDisclosure.onOpen}
      >
        <Icon as={EmrRoomCard} mr={2} h="24px" w="24px" color="blue" />
        Room Management
      </Button>
      <RoomManagement {...roomManagementDisclosure} />
    </>
  );
};

function InProcessSidebar(props: InProcessSidebarProps) {
  const { mainFilter = "room", didMainFilterChange } = props;
  const toast = useToast();
  const { data, error } = useInProcessCounters();
  const navigate = useNavigate();

  const [internalFilter, setInternalFilter] =
    useControllableState<InProcessSortOptions>({
      value: mainFilter,
      onChange: didMainFilterChange,
    });

  React.useEffect(() => {
    if (error) {
      toast({
        description: error.message,
      });
    }
  }, [toast, error]);

  const NAVIGATION_ITEMS = [
    {
      to: `/in-process/all-current-patients`,
      label: "All Current Patients",
      labelIcon: <Icon as={EmrPerson} color="blue" />,
      resultCount: data?.data.allCurrentPatient || 0,
    },
    {
      to: `/in-process/in-lobby`,
      label: "Lobby",
      labelIcon: <Icon as={SiStar} color="mint" />,
      resultCount: data?.data.lobby || 0,
    },
    {
      to: `/in-process/in-process`,
      label: "In Process",
      labelIcon: <Icon as={EmrInProcess} color="blue" />,
      resultCount: data?.data.inProcess || 0,
    },
    {
      to: `/in-process/observation`,
      label: "Observation",
      labelIcon: <Icon as={EmrBed} color="purple" />,
      resultCount: data?.data.observation || 0,
    },
    {
      to: `/in-process/awaiting-transfer`,
      label: "Transfers",
      labelIcon: <Icon as={EmrTransportation} color="yellow" />,
      resultCount: data?.data.transfer || 0,
    },
    // {
    //   to: `/in-process/recently-discharged`,
    //   label: "Recently Discharged",
    //   labelIcon: <Icon as={SiHome} color="green" />,
    //   resultCount: data?.data.recentlyDischarged || 0,
    // },
    {
      to: `/in-process/provider-open-charts`,
      label: "My Open Charts",
      labelIcon: <Icon as={OpenCharts} color="red" />,
      resultCount: data?.data.providerOpenCharts || 0,
    },
  ];
  return (
    <Sidebar>
      <SidebarActions>
        <BackButton onClick={() => navigate("/home", { replace: true })}>
          Home
        </BackButton>
      </SidebarActions>
      <SidebarHeader>
        <SidebarHeading>In Process</SidebarHeading>
        <SidebarHeading
          fontWeight="500"
          color="gray.600"
          size="sm"
          m="14px 0px 23px"
        >
          <Box>
            <InProcessOptionsRadio
              width="100%"
              h="32px"
              value={internalFilter}
              onChange={setInternalFilter as (filter: string) => void}
            >
              <InProcessOptionsRadioItem value="room" flex="1">
                Room #
              </InProcessOptionsRadioItem>
              <InProcessOptionsRadioItem value="createdAt" flex="1">
                Total Time
              </InProcessOptionsRadioItem>
            </InProcessOptionsRadio>
          </Box>
        </SidebarHeading>
      </SidebarHeader>
      <SidebarContent overflow="auto">
        <SidebarHeading size="md" fontSize="1.3125rem" marginBottom="0.5rem">
          Patient Filters
        </SidebarHeading>
        <SidebarNavigation>
          {NAVIGATION_ITEMS.map(({ to, label, labelIcon, resultCount }) => (
            <NavigationButton to={to} key={to}>
              <NavigationButtonContent label={label} labelIcon={labelIcon} />
              {resultCount !== undefined ? (
                <NavigationButtonIndicator>
                  {resultCount}
                </NavigationButtonIndicator>
              ) : null}
            </NavigationButton>
          ))}
        </SidebarNavigation>
        <RoomManagementButton />
      </SidebarContent>
      <SidebarBottom>
        <FaqButton />
        <SidebarUserView />
      </SidebarBottom>
    </Sidebar>
  );
}

export { InProcessSidebar };
