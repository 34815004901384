import { Button, useDisclosure } from "@chakra-ui/react";
import * as React from "react";
import {
  BaseVitalsInputModal,
  WeightVitalsInputModal,
  HeightVitalsInputModal,
  Spo2VitalsInputModal,
  TemperatureVitalsInputModal,
  SlashVitalsInputModal,
} from "./modals";
import { WeightUnit, Spo2Type, TemperatureType } from "../types/types";
import { VitalsCellProps, VitalsCell } from "./VitalsCell";
import { useVitalsContext } from "../contexts";

type VitalsEditCellProps = {
  onOpen: () => void;
  value?: string | number;
  disabledBtn?: boolean;
} & VitalsCellProps;

type SpecializedVitalsEditCellProps = Omit<VitalsEditCellProps, "onOpen">;

type BaseVitalsEditCellProps = {
  onSave: (value: string) => void;
  onValidate?: (value: string) => string | boolean;
  modalTitle: string;
} & SpecializedVitalsEditCellProps;

type WeightVitalsEditCellProps = {
  onSave: (value: number, unit: WeightUnit) => void;
} & SpecializedVitalsEditCellProps;

type HeightVitalsEditCellProps = {
  onSave: (value: string) => void;
} & SpecializedVitalsEditCellProps;

type Spo2VitalsEditCellProps = SpecializedVitalsEditCellProps & {
  onSave: (value: string, type: Spo2Type) => void;
  type?: Spo2Type;
};

type TemperatureVitalsEditCellProps = SpecializedVitalsEditCellProps & {
  onSave: (value: number, type: TemperatureType) => void;
};

function VitalsEditCell(props: VitalsEditCellProps) {
  const { value, onOpen, disabledBtn, ...restProps } = props;
  const { isEditMode } = useVitalsContext();

  if (value && !isEditMode) {
    return <VitalsCell {...restProps}>{value}</VitalsCell>;
  }

  return (
    <VitalsCell padding="7px 10px" {...restProps}>
      <Button
        position="unset"
        onClick={onOpen}
        height="30px"
        variant="action"
        borderRadius="20px"
        border="none"
        minWidth="unset"
        bgColor={isEditMode && value ? "transparent" : "gray.50"}
        width="100%"
        disabled={disabledBtn}
      >
        {value || "Add"}
      </Button>
    </VitalsCell>
  );
}

function BaseVitalsEditCell(props: BaseVitalsEditCellProps) {
  const { onSave, value, modalTitle, onValidate, ...vitalsEditCellProps } =
    props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <VitalsEditCell value={value} onOpen={onOpen} {...vitalsEditCellProps} />
      <BaseVitalsInputModal
        isOpen={isOpen}
        onClose={onClose}
        onSave={onSave}
        title={modalTitle}
        onValidate={onValidate}
        initialValue={value?.toString()}
      />
    </>
  );
}

function WeightVitalsEditCell(props: WeightVitalsEditCellProps) {
  const { onSave, value, ...vitalsEditCellProps } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <VitalsEditCell value={value} onOpen={onOpen} {...vitalsEditCellProps} />
      <WeightVitalsInputModal
        isOpen={isOpen}
        onClose={onClose}
        onSave={onSave}
        initialValue={value?.toString()}
      />
    </>
  );
}

function HeightVitalsEditCell(props: HeightVitalsEditCellProps) {
  const { onSave, value, ...vitalsEditCellProps } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <VitalsEditCell value={value} onOpen={onOpen} {...vitalsEditCellProps} />
      <HeightVitalsInputModal
        isOpen={isOpen}
        onClose={onClose}
        onSave={onSave}
        initialValue={value?.toString()}
      />
    </>
  );
}

function Spo2VitalsEditCell(props: Spo2VitalsEditCellProps) {
  const { onSave, value, type, ...vitalsEditCellProps } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <VitalsEditCell value={value} onOpen={onOpen} {...vitalsEditCellProps} />
      <Spo2VitalsInputModal
        isOpen={isOpen}
        onClose={onClose}
        onSave={onSave}
        initialValue={value?.toString()}
        initialType={type}
      />
    </>
  );
}

function TemperatureVitalsEditCell(props: TemperatureVitalsEditCellProps) {
  const { onSave, value, ...vitalsEditCellProps } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <VitalsEditCell value={value} onOpen={onOpen} {...vitalsEditCellProps} />
      <TemperatureVitalsInputModal
        isOpen={isOpen}
        onClose={onClose}
        onSave={onSave}
        initialValue={value?.toString()}
      />
    </>
  );
}

function SlashVitalsEditCell(props: BaseVitalsEditCellProps) {
  const { onSave, value, modalTitle, ...vitalsEditCellProps } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <VitalsEditCell value={value} onOpen={onOpen} {...vitalsEditCellProps} />
      <SlashVitalsInputModal
        isOpen={isOpen}
        onClose={onClose}
        onSave={onSave}
        title={modalTitle}
        initialValue={value?.toString()}
      />
    </>
  );
}

export type {
  BaseVitalsEditCellProps,
  VitalsEditCellProps,
  HeightVitalsEditCellProps,
  Spo2VitalsEditCellProps,
  WeightVitalsEditCellProps,
};

export {
  VitalsEditCell,
  BaseVitalsEditCell,
  HeightVitalsEditCell,
  Spo2VitalsEditCell,
  WeightVitalsEditCell,
  TemperatureVitalsEditCell,
  SlashVitalsEditCell,
};
