import * as React from "react";
import {
  Box,
  Button,
  chakra,
  CSSObject,
  Flex,
  HStack,
  Icon,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { CardProps, Card } from "shared";
import { EmrNotes } from "@medstonetech/slate-icons";
import { ChartNotesModal } from "modules";
import { SharedChartNotesModal } from "../chart-notes/SharedChartNotesModal";
import { useChartSectionNotesCount } from "modules/charts-shared/api/queries/useChartSectionNotesCount";
import { EncounterIntegrationCards } from "modules/reception/components/encounter-integration";

type WithNotes = {
  icon: JSX.Element;
  chartId: string;
  encounterId: string;
  sectionId: string;
  showNotesBtn?: true;
};

type NoNotes = {
  icon: JSX.Element;
  showNotesBtn: false;
  encounterId?: string;
  sectionId?: string;
  chartId?: string;
  showInformationSentButtons?: boolean;
};

type ActionBtnsProps = {
  showActionsBtns?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
};

type ChartSectionHeaderProps = (WithNotes | NoNotes) &
  ActionBtnsProps &
  CardProps;

type ChartSectionHeaderCardProps = {
  icon: JSX.Element;
  showNotesBtn: boolean;
  onOpen?: () => void;
  sectionNotesCount?: number; // number of notes - turn blue icon to indicate if has notes
  sectionBgColor?: string;
  sectionPadding?: string;
  encounterId?: string;
  showInformationSentButtons?: boolean;
  wrapperStyles?: CSSObject;
} & ActionBtnsProps &
  CardProps;

function isPropsNoNotes(props: ChartSectionHeaderProps): props is NoNotes {
  return (props as NoNotes).showNotesBtn === false;
}

function ChartSectionHeaderCard(props: ChartSectionHeaderCardProps) {
  const {
    onOpen,
    showNotesBtn,
    showActionsBtns,
    onCancel,
    onSave,
    isLoading,
    isDisabled,
    icon,
    children,
    sectionNotesCount,
    sectionBgColor = "gray.50",
    sectionPadding = "1.5rem",
    showInformationSentButtons = false,
    encounterId,
    wrapperStyles = {},
    ...cardProps
  } = props;

  return (
    <Box
      margin="-1.25rem"
      padding={sectionPadding}
      paddingBottom="0"
      height={showInformationSentButtons ? "6rem" : "4.5rem"}
      marginBottom={
        showInformationSentButtons ? "3.5rem !important" : "2rem !important"
      }
      position="sticky"
      top="-1.5rem"
      zIndex="10"
      background={sectionBgColor}
      sx={wrapperStyles}
    >
      {encounterId && showInformationSentButtons && (
        <Flex justify="center">
          <EncounterIntegrationCards encounterId={encounterId} />
        </Flex>
      )}
      <Card
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="1rem 1.25rem"
        variant="default"
        border="none"
        {...cardProps}
      >
        <HStack spacing="0.625rem">
          <Box
            borderRadius="5px"
            bg="blue"
            width="1.875rem"
            height="1.875rem"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {React.cloneElement(icon, {
              color: "white",
              fontSize: "1.25rem",
            })}
          </Box>
          <chakra.h2 fontSize="1.125rem" fontWeight="700">
            {children}
          </chakra.h2>
        </HStack>
        <HStack spacing="0.625rem">
          {showNotesBtn && (
            <IconButton
              aria-label="notes"
              icon={
                <Icon
                  as={EmrNotes}
                  color={
                    sectionNotesCount && sectionNotesCount > 0
                      ? "blue"
                      : "gray.450"
                  }
                  fontSize="1.375rem"
                />
              }
              onClick={onOpen}
              variant="ghost"
              height="2rem"
              width="2rem"
              minWidth="unset"
            />
          )}
          {showActionsBtns && (
            <Button
              variant="action"
              onClick={onSave}
              type="submit"
              colorScheme="blue"
              height="2rem"
              isLoading={isLoading}
              isDisabled={isLoading || isDisabled}
            >
              Save
            </Button>
          )}
        </HStack>
      </Card>
    </Box>
  );
}

function ChartSectionHeaderWithNotes(
  props: WithNotes & ActionBtnsProps & CardProps
) {
  const {
    chartId,
    encounterId,
    sectionId,
    icon,
    showNotesBtn = true,
    children,
    ...cardProps
  } = props;
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { data } = useChartSectionNotesCount({
    encounterId,
    sectionCode: sectionId,
    chartCode: chartId,
  });

  const sectionNotesCount = data?.data || 0;

  return (
    <>
      <ChartSectionHeaderCard
        onOpen={onOpen}
        showNotesBtn={showNotesBtn}
        icon={icon}
        sectionNotesCount={sectionNotesCount}
        {...cardProps}
      >
        {children}
      </ChartSectionHeaderCard>
      {isOpen && (
        <ChartNotesModal
          chartId={chartId}
          isOpen={isOpen}
          encounterId={encounterId}
          onClose={onClose}
          sectionId={sectionId}
          title={children}
        />
      )}
    </>
  );
}

function ChartSectionHeader(props: ChartSectionHeaderProps) {
  if (isPropsNoNotes(props)) {
    const { encounterId, sectionId, chartId, ...rest } = props;
    return <ChartSectionHeaderCard {...rest} />;
  }
  return <ChartSectionHeaderWithNotes {...props} />;
}

type SharedWithNotes = {
  icon: JSX.Element;
  encounterId: string;
  sectionId: string;
  showNotesBtn?: true;
  areNotesShared?: boolean;
  sectionBgColor?: string;
  sectionPadding?: string;
};

type SharedChartSectionHeaderProps = (SharedWithNotes | NoNotes) &
  ActionBtnsProps &
  CardProps & { wrapperStyles?: CSSObject };

function SharedChartSectionHeaderWithNotes(
  props: SharedWithNotes & ActionBtnsProps & CardProps
) {
  const {
    encounterId,
    sectionId,
    icon,
    showNotesBtn = true,
    children,
    sectionBgColor,
    sectionPadding,
    ...cardProps
  } = props;
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { data } = useChartSectionNotesCount({
    encounterId,
    sectionCode: sectionId,
  });

  const sectionNotesCount = data?.data || 0;

  return (
    <>
      <ChartSectionHeaderCard
        onOpen={onOpen}
        showNotesBtn={showNotesBtn}
        icon={icon}
        sectionNotesCount={sectionNotesCount}
        sectionBgColor={sectionBgColor}
        sectionPadding={sectionPadding}
        encounterId={encounterId}
        {...cardProps}
      >
        {children}
      </ChartSectionHeaderCard>
      {isOpen && (
        <SharedChartNotesModal
          encounterId={encounterId}
          isOpen={isOpen}
          onClose={onClose}
          sectionCode={sectionId}
          title={children}
        />
      )}
    </>
  );
}

function isPropsSharedNoNotes(
  props: SharedChartSectionHeaderProps
): props is NoNotes {
  return (props as NoNotes).showNotesBtn === false;
}

function SharedChartSectionHeader(props: SharedChartSectionHeaderProps) {
  if (isPropsSharedNoNotes(props)) {
    const { encounterId, sectionId, ...rest } = props;
    return <ChartSectionHeaderCard encounterId={encounterId} {...rest} />;
  }
  return <SharedChartSectionHeaderWithNotes {...props} />;
}

export type { ChartSectionHeaderProps, NoNotes, ActionBtnsProps };
export { ChartSectionHeader, SharedChartSectionHeader, ChartSectionHeaderCard };
