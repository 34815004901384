import * as React from "react";

import {
  BaseVitalsEditCell,
  SlashVitalsEditCell,
  Spo2VitalsEditCell,
  TemperatureVitalsEditCell,
} from "./VitalsEditCell";
import { Box, BoxProps, Text, useMergeRefs, useToast } from "@chakra-ui/react";
import { CalendarInput, UserAvatar } from "shared";
import { EdgeVitalsCell, VitalsCell } from "./VitalsCell";
import { usePatchVitalCheck } from "../api";
import {
  SPO2_LABEL,
  TEMPERATURE_TYPE_LABEL,
  VITALS_COLUMN_VALUE_KEYS,
} from "../constants/constants";
import { getCellDisplay, getEditCellDisplay } from "../utils/utils";
import { onValidateAcuityLevel, onValidatePainLevel } from "../utils";

import { PatchVitalPayload, VitalsColumn } from "../types";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { useVitalsContext } from "../contexts";

type VitalsInfiniteGridProps = BoxProps;

type VitalsAddColumnProps = {
  index: number;
};

type VitalsEditColumnProps = VitalsAddColumnProps & {
  record: VitalsColumn;
  encounterId: string;
};

function VitalsEditColumn(props: VitalsEditColumnProps) {
  const { index, record, encounterId } = props;
  const { isEditMode } = useVitalsContext();
  const toast = useToast();

  const { mutateAsync: updateVitalCheck, isLoading } = usePatchVitalCheck({
    encounterId,
    vitalCheckId: record.id || "",
  });

  const updateRecord = async (payload: PatchVitalPayload) => {
    try {
      await updateVitalCheck(payload);
      toast({ description: formMessages.updateSuccess("Vital check") });
    } catch (err) {
      toast({ description: extractApiErrorMessage(err) });
    }
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="240px"
      gridTemplateRows="repeat(13, 45px)"
      gridGap="1px"
    >
      <EdgeVitalsCell cellPosition="top">
        {isEditMode ? (
          <CalendarInput
            name={"takenAt"}
            dateFormat={"LL/dd/yyyy hh:mm a"}
            showIcon={false}
            showTimeInput={true}
            shouldCloseOnSelect={false}
            onChange={(value) => updateRecord([{ path: "takenAt", value }])}
            selected={record.takenAt ? new Date(record.takenAt) : null}
          />
        ) : (
          `${format(new Date(record.takenAt), "hh:mm")} (${index})`
        )}
      </EdgeVitalsCell>

      <BaseVitalsEditCell
        onSave={(value) => updateRecord([{ path: "acuity", value }])}
        value={getEditCellDisplay("acuity", record.acuity)}
        title={
          record.acuity
            ? getEditCellDisplay("acuity", record.acuity)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Acuity"
        onValidate={onValidateAcuityLevel}
      />

      <BaseVitalsEditCell
        onSave={(value) => updateRecord([{ path: "painLevel", value }])}
        value={getEditCellDisplay("painLevel", record.painLevel)}
        title={
          record.painLevel
            ? getEditCellDisplay("painLevel", record.painLevel)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Pain"
        onValidate={onValidatePainLevel}
      />

      <SlashVitalsEditCell
        onSave={(value) => updateRecord([{ path: "bp", value }])}
        value={getEditCellDisplay("bp", record.bp)}
        title={record.bp ? getEditCellDisplay("bp", record.bp) : undefined}
        disabledBtn={isLoading}
        modalTitle="BP"
      />

      <BaseVitalsEditCell
        onSave={(value) => updateRecord([{ path: "pulse", value }])}
        value={getEditCellDisplay("pulse", record.pulse)}
        title={
          record.pulse ? getEditCellDisplay("pulse", record.pulse) : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Pulse"
      />

      <BaseVitalsEditCell
        onSave={(value) => updateRecord([{ path: "rr", value }])}
        value={getEditCellDisplay("rr", record.rr)}
        title={record.rr ? getEditCellDisplay("rr", record.rr) : undefined}
        disabledBtn={isLoading}
        modalTitle="RR"
      />

      <TemperatureVitalsEditCell
        onSave={(value, type) =>
          updateRecord([
            { path: "temp", value: `${value} ${TEMPERATURE_TYPE_LABEL[type]}` },
          ])
        }
        value={getEditCellDisplay("temp", record.temp)}
        title={
          record.temp ? getEditCellDisplay("temp", record.temp) : undefined
        }
        disabledBtn={isLoading}
      />

      <Spo2VitalsEditCell
        onSave={(val, type) =>
          updateRecord([
            { path: "spO2", value: val },
            { path: "spO2Type", value: type },
          ])
        }
        value={getEditCellDisplay("spO2", record.spO2)}
        type={record.spO2Type}
        title={
          record.spO2 ? getEditCellDisplay("spO2", record.spO2) : undefined
        }
        disabledBtn={isLoading}
      />

      <VitalsCell
        title={record.spO2Type ? SPO2_LABEL[record.spO2Type] : undefined}
      >
        {record.spO2Type && SPO2_LABEL[record.spO2Type]}
      </VitalsCell>

      <BaseVitalsEditCell
        onSave={(value) => updateRecord([{ path: "gcs", value }])}
        value={getEditCellDisplay("gcs", record.gcs)}
        title={record.gcs ? getEditCellDisplay("gcs", record.gcs) : undefined}
        disabledBtn={isLoading}
        modalTitle="GCS"
      />

      <SlashVitalsEditCell
        onSave={(value) => updateRecord([{ path: "pupils", value }])}
        value={getEditCellDisplay("pupils", record.pupils)}
        title={
          record.pupils
            ? getEditCellDisplay("pupils", record.pupils)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Pupils"
      />
      <BaseVitalsEditCell
        onSave={(value) => updateRecord([{ path: "ambulatory", value }])}
        value={getEditCellDisplay("ambulatory", record.ambulatory)}
        title={
          record.ambulatory
            ? getEditCellDisplay("ambulatory", record.ambulatory)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Ambulatory"
      />

      <BaseVitalsEditCell
        onSave={(value) => updateRecord([{ path: "fetalHt", value }])}
        value={getEditCellDisplay("fetalHt", record.fetalHt)}
        title={
          record.fetalHt
            ? getEditCellDisplay("fetalHt", record.fetalHt)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Fetal HT"
      />

      <EdgeVitalsCell cellPosition="bottom"></EdgeVitalsCell>
    </Box>
  );
}

function VitalsAddColumn(props: VitalsAddColumnProps) {
  const { index } = props;

  const { setRecord, record, isCreateLoading: isLoading } = useVitalsContext();

  return (
    <Box
      display="grid"
      gridTemplateColumns="240px"
      gridTemplateRows="repeat(13, 45px)"
      gridGap="1px"
    >
      <EdgeVitalsCell cellPosition="top">
        {`${format(new Date(record.takenAt), "hh:mm")} (${index})`}
      </EdgeVitalsCell>

      <BaseVitalsEditCell
        onSave={(x) => setRecord((prev) => ({ ...prev, acuity: Number(x) }))}
        value={getEditCellDisplay("acuity", record.acuity)}
        title={
          record.acuity
            ? getEditCellDisplay("acuity", record.acuity)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Acuity"
        onValidate={onValidateAcuityLevel}
      />

      <BaseVitalsEditCell
        onSave={(x) => setRecord((prev) => ({ ...prev, painLevel: Number(x) }))}
        value={getEditCellDisplay("painLevel", record.painLevel)}
        title={
          record.painLevel
            ? getEditCellDisplay("painLevel", record.painLevel)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Pain"
        onValidate={onValidatePainLevel}
      />

      <SlashVitalsEditCell
        onSave={(x) => setRecord((prev) => ({ ...prev, bp: x }))}
        value={getEditCellDisplay("bp", record.bp)}
        title={record.bp ? getEditCellDisplay("bp", record.bp) : undefined}
        disabledBtn={isLoading}
        modalTitle="BP"
      />

      <BaseVitalsEditCell
        onSave={(x) => setRecord((prev) => ({ ...prev, pulse: Number(x) }))}
        value={getEditCellDisplay("pulse", record.pulse)}
        title={
          record.pulse ? getEditCellDisplay("pulse", record.pulse) : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Pulse"
      />

      <BaseVitalsEditCell
        onSave={(x) => setRecord((prev) => ({ ...prev, rr: Number(x) }))}
        value={getEditCellDisplay("rr", record.rr)}
        title={record.rr ? getEditCellDisplay("rr", record.rr) : undefined}
        disabledBtn={isLoading}
        modalTitle="RR"
      />

      <TemperatureVitalsEditCell
        onSave={(temp, type) =>
          setRecord((prev) => ({
            ...prev,
            temp: `${temp} (${TEMPERATURE_TYPE_LABEL[type]})`,
          }))
        }
        value={getEditCellDisplay("temp", record.temp)}
        title={
          record.temp ? getEditCellDisplay("temp", record.temp) : undefined
        }
        disabledBtn={isLoading}
      />

      <Spo2VitalsEditCell
        onSave={(val, type) =>
          setRecord((prev) => ({ ...prev, spO2: val, spO2Type: type }))
        }
        value={getEditCellDisplay("spO2", record.spO2)}
        title={
          record.spO2 ? getEditCellDisplay("spO2", record.spO2) : undefined
        }
        disabledBtn={isLoading}
      />

      <VitalsCell
        title={record.spO2Type ? SPO2_LABEL[record.spO2Type] : undefined}
      >
        {record.spO2Type && SPO2_LABEL[record.spO2Type]}
      </VitalsCell>

      <BaseVitalsEditCell
        onSave={(x) => setRecord((prev) => ({ ...prev, gcs: Number(x) }))}
        value={getEditCellDisplay("gcs", record.gcs)}
        title={record.gcs ? getEditCellDisplay("gcs", record.gcs) : undefined}
        disabledBtn={isLoading}
        modalTitle="GCS"
      />

      <SlashVitalsEditCell
        onSave={(x) => setRecord((prev) => ({ ...prev, pupils: x }))}
        value={getEditCellDisplay("pupils", record.pupils)}
        title={
          record.pupils
            ? getEditCellDisplay("pupils", record.pupils)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Pupils"
      />
      <BaseVitalsEditCell
        onSave={(x) => setRecord((prev) => ({ ...prev, ambulatory: x }))}
        value={getEditCellDisplay("ambulatory", record.ambulatory)}
        title={
          record.ambulatory
            ? getEditCellDisplay("ambulatory", record.ambulatory)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Ambulatory"
      />

      <BaseVitalsEditCell
        onSave={(x) => setRecord((prev) => ({ ...prev, fetalHt: x }))}
        value={getEditCellDisplay("fetalHt", record.fetalHt)}
        title={
          record.fetalHt
            ? getEditCellDisplay("fetalHt", record.fetalHt)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Fetal HT"
      />

      <EdgeVitalsCell cellPosition="bottom"></EdgeVitalsCell>
    </Box>
  );
}

function genDummyColumns(n: number) {
  return Array(n)
    .fill(0)
    .map((_, index) => (
      <Box
        key={index}
        display="grid"
        gridTemplateColumns="240px"
        gridTemplateRows="repeat(13, 45px)"
        gridGap="1px"
      >
        <EdgeVitalsCell paddingTop="12px" cellPosition="top" />
        {VITALS_COLUMN_VALUE_KEYS.map((key) => (
          <VitalsCell key={key} />
        ))}
        <EdgeVitalsCell cellPosition="bottom" />
      </Box>
    ));
}

const VitalsInfiniteGrid = React.forwardRef<
  HTMLDivElement,
  VitalsInfiniteGridProps
>((props, ref) => {
  const { encounterId = "" } = useParams<{ encounterId: string }>();
  const { ...boxProps } = props;
  const { isEditMode, isAddMode, vitalsData } = useVitalsContext();
  const [dummyColumns, setDummyColumns] = React.useState(0);
  const gridRef = React.useRef<HTMLDivElement>(null);
  const mergedRefs = useMergeRefs(ref, gridRef);

  const records = vitalsData?.vitalChecks;
  const recordsLength = records?.length || 0;

  React.useEffect(() => {
    const f = () => {
      if (gridRef.current) {
        const containerWidth = gridRef.current.clientWidth;
        const dc = Math.ceil(containerWidth / 240) - recordsLength;
        setDummyColumns(dc < 0 ? 0 : dc);
      }
    };
    f();

    window.addEventListener("resize", f);

    return () => {
      window.removeEventListener("resize", f);
    };
  }, [recordsLength]);

  return (
    <Box ref={mergedRefs} overflow="overlay" borderRadius="10px" {...boxProps}>
      <Box
        display="grid"
        gridTemplateColumns={
          isAddMode
            ? `repeat(${recordsLength + 1 + dummyColumns}, 240px)`
            : `repeat(${recordsLength + dummyColumns}, 240px)`
        }
        gridGap="1px"
        bg="#D3D3D6"
        border="1px solid #D1D1D6"
        borderRadius="10px"
        width="max-content"
      >
        {isAddMode && <VitalsAddColumn index={recordsLength + 1} />}
        {records?.map((record, index) =>
          isEditMode ? (
            <VitalsEditColumn
              key={index}
              index={recordsLength - index}
              record={record}
              encounterId={encounterId}
            />
          ) : (
            <Box
              key={index}
              display="grid"
              gridTemplateColumns="240px"
              gridTemplateRows="repeat(13, 45px)"
              gridGap="1px"
            >
              <EdgeVitalsCell cellPosition="top">
                {`${format(new Date(record.takenAt), "hh:mm")} (${
                  recordsLength - index
                })`}
              </EdgeVitalsCell>
              {VITALS_COLUMN_VALUE_KEYS.map((x, i) => (
                <VitalsCell
                  key={i}
                  color={
                    record[x] !== undefined &&
                    record[x] !== null &&
                    record[x] !== ""
                      ? "gray.650"
                      : "gray.450"
                  }
                  title={record[x] ? getCellDisplay(x, record[x]) : undefined}
                >
                  {getCellDisplay(x, record[x])}
                </VitalsCell>
              ))}
              <EdgeVitalsCell cellPosition="bottom">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  flex={1}
                  paddingLeft="4px"
                >
                  <UserAvatar
                    size="xs"
                    fontSize="1rem"
                    picture={
                      record.takenBy && record.takenBy.pictureUrl
                        ? record.takenBy.pictureUrl
                        : ""
                    }
                    title={record.takenBy ? `${record.takenBy.fullName}` : ""}
                  />
                  <Box display="flex" flexDirection="column" marginLeft="10px">
                    <Text fontSize="15px">
                      {record.takenBy ? `${record.takenBy.fullName}` : ""}
                    </Text>
                    <Text fontSize="12px">
                      {record.takenBy ? record.takenBy.teams : ""}
                    </Text>
                  </Box>
                </Box>
              </EdgeVitalsCell>
            </Box>
          )
        )}
        {genDummyColumns(dummyColumns)}
      </Box>
    </Box>
  );
});

VitalsInfiniteGrid.displayName = "VitalsInfiniteGrid";

export { VitalsInfiniteGrid };
