import {
  Box,
  Icon,
  useDisclosure,
  Text,
  IconButton,
  HStack,
  VStack,
  Image,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { Button, Input, StaffAvatar } from "shared";
import { MdAddCircle } from "react-icons/md";
import { SignatureTypeModal } from "./SignatureTypeModal";
import {
  DispositionForm,
  DispositionSignatureForm,
} from "modules/charts-shared/types";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  SharedChartSubsectionHeader,
} from "../chart";
import { EmrRemove } from "@medstonetech/slate-icons";
import { ProviderChartCode, SignatureType, TriageChartCode } from "enums";
import { Control, useFieldArray } from "react-hook-form";
import { enumMapper } from "utils";
import { AddRounded } from "icons";
import { SignatureScribedInPresenseOfModal } from "./SignatureScribedInPresenseOfModal";
import { useAuth0 } from "@auth0/auth0-react";
import { USERID_CLAIMS } from "system-constants";

type DispositionSignatureItemProps = {
  signature: DispositionSignatureForm;
  onDelete: (userId: string) => void;
  signatures: DispositionSignatureForm[];
  control: Control<DispositionForm>;
};

const DispositionSignatureItem = ({
  signature,
  onDelete,
  signatures,
  control,
}: DispositionSignatureItemProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user: currentUser } = useAuth0();
  const userId = currentUser ? currentUser[USERID_CLAIMS] : "";

  const { update } = useFieldArray({
    control,
    name: "signatures",
  });

  const handleRemoveScribed = () => {
    const index = signatures.findIndex(
      (item) => item.userId === signature.userId
    );

    const signatureUpdated = signatures.find(
      (item) => item.userId === signature.userId
    );

    if (index >= 0 && !!signatureUpdated) {
      update(index, {
        scribedInPresenseOf: undefined,
        scribedInPresenseOfUser: { id: "", fullName: "" },
      });
      onClose();
    }
  };

  return (
    <ChartSubsectionContent>
      {signature.type === "Scribe" && (
        <ChartRow>
          <Text pl={4}>
            I personally scribed the information in this document as dictated to
            me and in the presence of
          </Text>
          <InputGroup size="lg" w="300px">
            <Input
              pr="4.5rem"
              isReadOnly
              value={signature.scribedInPresenseOfUser?.fullName}
            />
            <InputRightElement>
              {!signature.scribedInPresenseOf ? (
                <IconButton
                  aria-label="add-scribe"
                  icon={
                    <Icon as={AddRounded} fontSize="1.25rem" color={"blue"} />
                  }
                  variant="ghost"
                  onClick={onOpen}
                />
              ) : (
                <IconButton
                  aria-label="remove-scribe"
                  icon={
                    <Icon
                      as={EmrRemove}
                      fontSize="1.25rem"
                      color={"lightgray"}
                    />
                  }
                  variant="ghost"
                  onClick={handleRemoveScribed}
                />
              )}
            </InputRightElement>
          </InputGroup>
          <SignatureScribedInPresenseOfModal
            isOpen={isOpen}
            onClose={onClose}
            userId={signature.userId}
            signatures={signatures}
            control={control}
          />
        </ChartRow>
      )}
      {signature.type === "SupervisingProvider" && (
        <ChartRow>
          <Text pl={4}>
            I have personally seen, spoken with, and examined the labs and
            imaging. I agree with the findings and plan of care with the overall
            addendum.
          </Text>
        </ChartRow>
      )}
      <ChartRow templateColumns="repeat(4, 1fr)">
        <ChartRowElement
          type="container"
          content={
            <>
              <HStack>
                <StaffAvatar
                  w="32px"
                  h="32px"
                  size="xs"
                  fontSize=".75rem"
                  userName={signature.user?.fullName || ""}
                  profileUrl={signature.user?.pictureUrl || ""}
                />
                <VStack spacing="0" alignItems="flex-start">
                  <Box
                    fontSize="1.0625rem"
                    fontWeight="600"
                    lineHeight="1.5rem"
                    color="black"
                  >
                    {signature.user?.fullName}
                  </Box>
                  <Box fontSize="0.875rem" fontWeight="400" lineHeight="1rem">
                    {signature.user?.teams}
                  </Box>
                </VStack>
              </HStack>
            </>
          }
        />
        <ChartRowGroup>
          <ChartRowElement
            type="container"
            content={
              <>
                <HStack>
                  <Text>E-signed by:</Text>
                  <Text
                    color="black"
                    fontSize="1.0625rem"
                    fontWeight="600"
                    lineHeight="1.5rem"
                  >
                    {signature.user?.fullName}
                  </Text>
                </HStack>
              </>
            }
          />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            type="container"
            minW={300}
            maxW={400}
            minH={50}
            content={
              <Box>
                <Image
                  src={signature.user?.signature}
                  height="100%"
                  width="100%"
                />
              </Box>
            }
          />
        </ChartRowGroup>
        <ChartRowGroup justifyContent="end" pr={4}>
          <IconButton
            aria-label="sign"
            size="iconSm"
            icon={<Icon as={EmrRemove} fontSize="1.25rem" color={"red"} />}
            maxWidth="24px"
            variant="ghost"
            disabled={signature.createdBy !== userId}
            onClick={() => onDelete(signature.userId)}
          />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
};

type DispositionSignaturesProps = DispositionSignatureSubsectionProps & {
  title: string;
  type: SignatureType;
};

const DispositionSignatures = ({
  title,
  chartCode,
  encounterId,
  sectionCode,
  chartType,
  signatures,
  type,
  control,
}: DispositionSignaturesProps) => {
  const signaturesFiltered =
    signatures?.filter((signature) => signature.type === type) ?? [];

  const { remove } = useFieldArray({
    control,
    name: "signatures",
  });

  const { insert } = useFieldArray({
    control,
    name: "signaturesToDelete",
  });

  const handleDelete = (userId: string) => {
    const index = signatures?.findIndex(
      (signature) => signature.userId === userId
    );

    if (index !== undefined) {
      remove(index);
    }

    const signature = signatures?.find((item) => item.userId === userId);

    if (signature && signature.id) {
      insert(0, signature);
    }
  };

  return !!signaturesFiltered?.length ? (
    <ChartSubsection
      header={
        <SharedChartSubsectionHeader
          chartCode={chartCode}
          encounterId={encounterId}
          sectionCode={sectionCode}
          subsectionCode="Q006"
          subsectionLabel={`${title} Signature`}
          showNotesBtn={chartType === "Triage" ? false : undefined}
          showAuditsBtn={chartType === "Chart" || chartType === "Triage"}
        >
          {title} Signature
        </SharedChartSubsectionHeader>
      }
      content={
        <>
          {signaturesFiltered.map((signature) => {
            return (
              <DispositionSignatureItem
                key={`${signature.userId}`}
                signature={signature}
                onDelete={handleDelete}
                control={control}
                signatures={signatures}
              />
            );
          })}
        </>
      }
    />
  ) : (
    <></>
  );
};

type DispositionSignatureSubsectionProps = {
  chartCode: TriageChartCode | ProviderChartCode;
  encounterId: string;
  sectionCode: string;
  chartType: string;
  signatures: DispositionSignatureForm[];
  control: Control<DispositionForm>;
};

const DispositionSignatureSubsection = (
  props: DispositionSignatureSubsectionProps
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const sigantureTypes = enumMapper.getOptions("signatureType");
  return (
    <>
      <Box display="flex" justifyContent="flex-end" columnGap="25px" pt={2}>
        <Button variant="label" color="blue" onClick={onOpen}>
          <Icon as={MdAddCircle} mr="8px" />
          add signature
        </Button>
        <SignatureTypeModal isOpen={isOpen} onClose={onClose} {...props} />
      </Box>

      {sigantureTypes.map((type) => {
        return (
          <DispositionSignatures
            key={type.value}
            title={type.label}
            type={type.value}
            {...props}
          />
        );
      })}
    </>
  );
};

export { DispositionSignatureSubsection };
