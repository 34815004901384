import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { ChartFormSubsection } from "modules";

type UseChartApplicationParams = {
  encounterId: string;
  chartCode: string;
  sectionId: string;
};

function useChartApplication(params: UseChartApplicationParams) {
  const { encounterId, chartCode, sectionId } = params;
  return useAxiosQuery(
    ["chartApplication", { encounterId, chartCode, sectionId }],
    () =>
      axiosChartInstance.get<ChartFormSubsection[]>(
        `v1/chart-application/${encounterId}/${chartCode}/${sectionId}`
      )
  );
}

export type { UseChartApplicationParams };
export { useChartApplication };
