import { axiosFacilityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { PCP, PCPPayload } from "../../types";

function useUpdatePrimaryCarePhysician(physicianId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updatePrimaryCarePyhisician"],
    (input: PCPPayload) => {
      return axiosFacilityInstance.put<PCP>(
        `/v1/physician/${physicianId}`,
        input
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("primaryCarePhysicians");
      },
    }
  );
}

export { useUpdatePrimaryCarePhysician };
