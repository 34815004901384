import { Box, chakra, Icon, IconButton } from "@chakra-ui/react";
import { LeftArrow, RightArrow } from "icons";
import * as React from "react";

type PaginationState = { currentPage: number; pagesCount: number };

type PaginationControlProps = {
  currentPage: number;
  pagesCount: number;
  onClickNext: (state: PaginationState) => void;
  onClickPrevious: (state: PaginationState) => void;
};

function PaginationControl(props: PaginationControlProps) {
  const { currentPage, onClickNext, onClickPrevious, pagesCount } = props;
  const hasPreviousPage = pagesCount > 0 && currentPage > 1;
  const hasNextPage = pagesCount > 0 && currentPage < pagesCount;

  return (
    <Box display="flex" alignItems="center">
      <chakra.span
        color="gray.550"
        fontSize="1.0625rem"
        fontWeight="400"
        marginRight="1.75rem"
        width="max-content"
      >{`${pagesCount === 0 ? 0 : currentPage} of ${pagesCount}`}</chakra.span>
      <IconButton
        aria-label="previous page"
        variant="icon"
        marginRight="2.375rem"
        onClick={() => onClickPrevious({ currentPage, pagesCount })}
        size="md"
        icon={<Icon color="gray.550" as={LeftArrow} />}
        isDisabled={!hasPreviousPage}
      />
      <IconButton
        aria-label="next page"
        variant="icon"
        onClick={() => onClickNext({ currentPage, pagesCount })}
        icon={
          <Icon
            width="0.6875rem"
            height="1.25rem"
            color="gray.550"
            as={RightArrow}
          />
        }
        isDisabled={!hasNextPage}
      />
    </Box>
  );
}

export type { PaginationControlProps, PaginationState };
export { PaginationControl };
