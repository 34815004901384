import * as React from "react";
import { axiosPatientInstance } from "config";
import { useAxiosMutation } from "hooks";
import { EncounterInsuranceContext } from "modules/reception/contexts";
import {
  InProcessSection,
  InsuranceSection,
  ReceptionSection,
} from "modules/reception/types";
import { useQueryClient } from "react-query";

type UsePatchEncounterFieldProps = {
  reloadInfo?: boolean;
  reloadSections?: (ReceptionSection | InsuranceSection | InProcessSection)[];
  encounterId: string;
};

type UsePatchEncounterFieldPayload = {
  path: string;
  value: unknown;
}[];

function usePatchEncounterField(props: UsePatchEncounterFieldProps) {
  const { encounterId, reloadInfo = true, reloadSections = [] } = props;
  const queryClient = useQueryClient();

  const insuranceContext = React.useContext(EncounterInsuranceContext);

  return useAxiosMutation(
    ["patchEncounterField", props],
    (payload: UsePatchEncounterFieldPayload) =>
      axiosPatientInstance.patch(
        `v1/encounter/${encounterId}/form/save`,
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterFormStatus", { encounterId }]);
        if (reloadInfo) {
          if (reloadSections.length === 0 || reloadSections.includes("info")) {
            queryClient.invalidateQueries(["encounterInfo", { encounterId }]);
            queryClient.invalidateQueries([
              "chartPatientCard",
              { encounterId },
            ]);
          }

          if (
            reloadSections.includes("sideInfo") ||
            reloadSections.length === 0
          )
            queryClient.invalidateQueries([
              "encounterSideInfo",
              { encounterId },
            ]);
          if (
            reloadSections.length === 0 ||
            reloadSections.includes("overview")
          )
            queryClient.invalidateQueries([
              "encounterOverview",
              { encounterId },
            ]);
          if (
            insuranceContext &&
            (reloadSections.length === 0 ||
              reloadSections.some((sect) => sect.startsWith("insurance")))
          )
            queryClient.invalidateQueries([
              "encounterInsurance",
              { encounterId },
            ]);
          // Get only the insurance relevant sections
          insuranceContext?.setReloadSections([
            ...(reloadSections.filter((sect) =>
              sect.startsWith("insurance")
            ) as InsuranceSection[]),
          ]);
          if (
            reloadSections.length === 0 ||
            reloadSections.includes("inProcess")
          ) {
            queryClient.invalidateQueries(["patientDetails", { encounterId }]);
            queryClient.invalidateQueries(["inProcessEncounterList"]);
          }
        }
      },
    }
  );
}

export type { UsePatchEncounterFieldProps };
export { usePatchEncounterField };
