import * as React from "react";
import { Segment, SegmentOption } from "shared";
import {
  VitalsInputModal,
  VitalsInputModalKeypad,
  VitalsInputModalValueBox,
  BaseVitalsInputModalProps,
} from "./VitalsInputModal";
import { TemperatureType } from "../../types/types";
import { Button } from "@chakra-ui/react";
import { Close } from "icons";

type TemperatureVitalsInputModalProps = Omit<
  BaseVitalsInputModalProps,
  "title" | "onSave"
> & {
  onSave: (value: number, type: TemperatureType) => void;
};

function getType(index: number) {
  const types: Record<number, TemperatureType> = {
    0: "tm",
    1: "oral",
    2: "rectal",
  };

  return types[index] || "lbs";
}

function getIndexType(type: TemperatureType) {
  const types: Record<TemperatureType, number> = {
    tm: 0,
    oral: 1,
    rectal: 2,
  };

  return types[type] || 0;
}

function TemperatureVitalsInputModal(props: TemperatureVitalsInputModalProps) {
  const { onSave, initialValue, ...vitalsInputModalProps } = props;
  const { onClose } = vitalsInputModalProps;
  const [value, setValue] = React.useState("");
  const [type, setType] = React.useState(0);

  React.useEffect(() => {
    if (initialValue) {
      const [temp, tempType] = initialValue.split(" ");
      setValue(temp);
      if (tempType) {
        setType(getIndexType(tempType.toLowerCase() as TemperatureType));
      }
    }
  }, [initialValue]);

  return (
    <VitalsInputModal title="Temp" {...vitalsInputModalProps}>
      <VitalsInputModalValueBox
        selector={
          <Segment isFitted onChange={setType} index={type}>
            <SegmentOption>TM</SegmentOption>
            <SegmentOption>Oral</SegmentOption>
            <SegmentOption>Rectal</SegmentOption>
          </Segment>
        }
      >
        {value}
      </VitalsInputModalValueBox>
      <VitalsInputModalKeypad
        customKeyLabel="."
        onClickCustomKey={() => setValue((prev) => `${prev}.`)}
        onClickKey={(x) => setValue((prev) => `${prev}${x}`)}
        onSave={() => {
          const num = Number(value);
          onSave(Number.isNaN(num) ? 0 : num, getType(type));
          onClose();
        }}
        onDelete={() => setValue((prev) => prev.slice(0, prev.length - 1))}
      />
      <Button
        onClick={onClose}
        variant="solid"
        m={0}
        minW="unset"
        backgroundColor="gray.350"
        w="30px"
        h="30px"
        position="absolute"
        top={3}
        right={3}
        maxH="unset"
        colorScheme="gray"
      >
        <Close width="10px" height="10px" color="gray.600" />
      </Button>
    </VitalsInputModal>
  );
}

export type { TemperatureVitalsInputModalProps };
export { TemperatureVitalsInputModal };
