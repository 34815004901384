import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { EmrSignature } from "@medstonetech/slate-icons";
import { ProviderChartCode, SignatureType, TriageChartCode } from "enums";
import { RightArrow } from "icons";
import {
  DispositionForm,
  DispositionSignatureForm,
} from "modules/charts-shared/types";
import React from "react";
import { Control } from "react-hook-form";
import { DividerList } from "shared";
import * as enumMapper from "utils/enum-mapper";
import { SelectUserSignatureTypeModal } from "./SignatoryModal";

type SignatureTypeItemProps = {
  label: string;
  onClick: () => void;
};

const SignatureTypeItem = ({ label, onClick }: SignatureTypeItemProps) => {
  return (
    <Button
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      variant="label"
      width="510px"
      padding="1rem"
      onClick={onClick}
    >
      <HStack spacing="1rem">
        <Box w="1.25rem" h="1.25rem">
          <Icon as={EmrSignature} fontSize="1.25rem" color="blue" />
        </Box>
        <Box fontSize="1.0625rem" color="gray.650">
          {label}
        </Box>
      </HStack>
      <HStack spacing="1rem">
        <Icon as={RightArrow} fontSize="0.875rem" color="gray.450" />
      </HStack>
    </Button>
  );
};

type SignatureTypeModalContentProps = Pick<
  SignatureTypeModalProps,
  | "onClose"
  | "chartCode"
  | "chartType"
  | "encounterId"
  | "sectionCode"
  | "signatures"
  | "control"
>;

const SignatureTypeModalContent = ({
  onClose: onSignatureTypeClose,
  ...rest
}: SignatureTypeModalContentProps) => {
  const types = enumMapper.getOptions("signatureType");
  const [selectedType, setSelectedType] = React.useState<
    | {
        value: SignatureType;
        label: string;
      }
    | undefined
  >(undefined);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ModalHeader
        display="flex"
        justifyContent="space-between"
        fontSize="1rem"
        padding="20px 16px 30px"
      >
        <Button variant="label" onClick={onSignatureTypeClose} color="blue">
          Cancel
        </Button>
        <Heading fontWeight="600" fontSize="1.0625rem">
          Signature Type
        </Heading>
        <Box width="50px" />
      </ModalHeader>
      <ModalBody padding="0 15px 15px">
        <Box borderRadius="10px" bg="white">
          <DividerList dividerLeftMargin="52px">
            {types.map((type) => (
              <SignatureTypeItem
                key={type.value}
                label={type.label}
                onClick={() => {
                  setSelectedType(type);
                  onOpen();
                }}
              />
            ))}
          </DividerList>
        </Box>
      </ModalBody>
      <SelectUserSignatureTypeModal
        isOpen={isOpen}
        onClose={onClose}
        onCloseTypeModal={onSignatureTypeClose}
        selectedType={selectedType?.value}
        title={selectedType?.label ?? ""}
        {...rest}
      />
    </>
  );
};

type SignatureTypeModalProps = Pick<
  UseDisclosureReturn,
  "isOpen" | "onClose"
> & {
  chartCode: TriageChartCode | ProviderChartCode;
  encounterId: string;
  sectionCode: string;
  chartType: string;
  signatures: DispositionSignatureForm[] | null;
  control: Control<DispositionForm>;
};

const SignatureTypeModal = (props: SignatureTypeModalProps) => {
  const { isOpen, onClose, ...rest } = props;

  return (
    <Modal {...props} motionPreset="scale" blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        maxW="unset"
        width="540px"
        height="90%"
        margin="0"
        overflowY="auto"
        overflowX="hidden"
        alignSelf="center"
      >
        {isOpen && <SignatureTypeModalContent onClose={onClose} {...rest} />}
      </ModalContent>
    </Modal>
  );
};

export { SignatureTypeModal };
