import { Box, Icon, IconButton, Text } from "@chakra-ui/react";
import { EmrHeart } from "@medstonetech/slate-icons";
import { OrderElementResponse } from "modules/order-list/types";
import { MouseEventHandler } from "react";
import { MdFavoriteBorder } from "react-icons/md";
import { Button, Loading } from "shared";
import { toDisplay } from "utils/enum-mapper";

type OrderFormItemCardProps = {
  item: OrderElementResponse;
  isMyFavorite: boolean;
  onClick: () => void;
  onFavorite: () => void;
  isLoading?: boolean;
};

function OrderFormItemCard({
  item,
  isMyFavorite,
  isLoading,
  onClick,
  onFavorite,
}: OrderFormItemCardProps) {
  const handleFavorite: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onFavorite();
  };

  const getFavoriteColor = () => {
    if (isMyFavorite) return "blue";
    if (item.favorite) return "orange";
    return "gray";
  };

  return (
    <Button
      variant="card-2"
      key={item.id}
      w="100%"
      justifyContent="flex-start"
      columnGap="20px"
      onClick={onClick}
    >
      <Box w="20px">
        {isLoading ? (
          <Loading spinnerProps={{ size: "sm", color: "gray" }} w="auto" />
        ) : (
          <IconButton
            variant="icon"
            aria-label="Favorite"
            onClick={handleFavorite}
          >
            <Icon
              as={item.favorite || isMyFavorite ? EmrHeart : MdFavoriteBorder}
              color={getFavoriteColor()}
            />
          </IconButton>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        flex={1}
      >
        <Text>{item.description}</Text>
        {item.orderFormElementCategory && (
          <Text fontSize="0.983rem" color="gray" fontWeight="0.983rem">
            {toDisplay(
              "orderFormElementCategory",
              item.orderFormElementCategory
            )}
          </Text>
        )}
      </Box>
    </Button>
  );
}

export { OrderFormItemCard };
