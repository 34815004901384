import * as React from "react";
import {
  useController,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import DatePicker from "react-datepicker";
import { useMergeRefs } from "@chakra-ui/react";
import { CalendarInput, CalendarInputProps } from "shared";
import { useDivideControlProps } from "./hooks";

const omittedProps = ["selected", "name", "onChange"] as const;

type FormCalendarInputProps<TFieldValues extends FieldValues> =
  UseControllerProps<TFieldValues> &
    Omit<CalendarInputProps, typeof omittedProps[number]> & {
      onChange?: CalendarInputProps["onChange"];
    };

function FormCalendarInputInner<TFieldValues extends FieldValues>(
  props: FormCalendarInputProps<TFieldValues>,
  ref?: React.ForwardedRef<DatePicker>
) {
  const { onChange: onChangeProp, ...restProps } = props;
  const [calendarProps, controllerProps] = useDivideControlProps<
    TFieldValues,
    FormCalendarInputProps<TFieldValues>
  >(restProps);

  const {
    field: { ref: fieldRef, value, onChange, ...restField },
  } = useController(controllerProps);

  const calendarInputRef = useMergeRefs(ref, fieldRef);

  const handleChange: CalendarInputProps["onChange"] = (date, event) => {
    onChangeProp?.(date, event);
    onChange(date);
  };

  return (
    <CalendarInput
      ref={calendarInputRef}
      {...calendarProps}
      {...restField}
      selected={value && new Date(value)}
      onChange={handleChange}
    />
  );
}

const FormCalendarInput = React.forwardRef(FormCalendarInputInner) as <
  TFieldValues extends FieldValues
>(
  props: FormCalendarInputProps<TFieldValues> & {
    ref?: React.ForwardedRef<DatePicker>;
  }
) => ReturnType<typeof FormCalendarInputInner>;

export { FormCalendarInput };
export type { FormCalendarInputProps };
