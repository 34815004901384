import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { EmrNotes, EmrPerson } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import { OrderGroup } from "enums";
import { useScrollbarWidth } from "hooks";
import { AddRounded } from "icons";
import {
  AddPatientChartModal,
  ChartNotesModal,
  ChartPicker,
  OrderChartNotesModal,
  SidebarChartPatientCard,
  useChartSectionNotesCount,
  VitalsTimerBadge,
} from "modules/charts-shared";
import { FaqButton } from "modules/faq/components";
import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BackButton,
  Counter,
  NavigationButton,
  NavigationButtonContent,
  NavigationButtonContentProps,
  Sidebar,
  SidebarActions,
  SidebarBottom,
  SidebarContent,
  SidebarHeader,
  SidebarUserView,
} from "shared";
import {
  AuditCommentsSectionIndicator,
  AuditCommentsSharedSectionIndicator,
} from "./audit-comments";
import { SharedChartNotesModal } from "./chart-notes/SharedChartNotesModal";
import { ChartSectionHeaderMenu } from "./ChartSectionHeader";
import { useDiagramsCounter } from "./diagrams/api";
import { DiagramCanvasModal } from "./diagrams/DiagramCanvasModal";

type ChartsSidebarProps = {
  children?: React.ReactNode;
};
type ChartNavigationButtonNotesProps = {
  hasNotes: true;
} & (
  | {
      notesType: "general" | "shared";
    }
  | {
      notesType: "order";
      orderType: OrderGroup;
    }
);

type ChartNavigationButtonProps = {
  sectionId: string;
  encounterId: string;
  to: string;
  chartCode: string;
  hasDiagrams?: boolean;
  isAccessible?: boolean;
  isShared?: boolean;
} & { hasAudits?: boolean } & (
    | { hasNotes?: false }
    | ChartNavigationButtonNotesProps
  ) &
  NavigationButtonContentProps;

type AddNewChartButtonProps = {
  encounterId: string;
};

function AddNewChartButton(props: AddNewChartButtonProps) {
  const { encounterId } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Button
        fontSize="0.9375rem"
        color="blue"
        display="flex"
        variant="label"
        alignItems="center"
        marginLeft="7px"
        onClick={onOpen}
      >
        <Icon as={AddRounded} fontSize="1.125rem" marginRight="16px" />
        Add New Chart
      </Button>
      <AddPatientChartModal
        isOpen={isOpen}
        onClose={onClose}
        encounterId={encounterId}
      />
    </>
  );
}

type DiagramsButtonProps = {
  encounterId: string;
  chartCode: string;
};

function DiagramsButton(props: DiagramsButtonProps) {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { chartCode, encounterId } = props;
  const { data, isLoading } = useDiagramsCounter(encounterId, chartCode);

  const diagramsCounter = data?.data || 0;

  return (
    <>
      <Box position="relative">
        <IconButton
          aria-label="notes"
          isLoading={isLoading}
          icon={
            <Box
              width="1.25rem"
              height="1.25rem"
              borderRadius="50%"
              bg={diagramsCounter > 0 ? "blue" : "gray.450"}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon
                as={EmrPerson}
                color="gray.100"
                width="0.4375rem"
                height="0.5.875rem"
              />
            </Box>
          }
          variant="icon"
          fontSize="1.4375rem"
          minWidth="unset"
          onClick={(e) => {
            e.preventDefault();
            onOpen();
          }}
        />
        {diagramsCounter > 0 && (
          <Counter
            size="sm"
            count={diagramsCounter}
            position="absolute"
            right="-2px"
            top="-2px"
          />
        )}
      </Box>
      <React.Suspense fallback={"Loading..."}>
        <DiagramCanvasModal
          isOpen={isOpen}
          diagrams={[]}
          onClose={onClose}
          {...props}
        />
      </React.Suspense>
    </>
  );
}

type CharNotesButtonProps = {
  onOpen: () => void;
  encounterId: string;
  chartCode: string;
  sectionCode: string;
};

function ChartNotesButton(props: CharNotesButtonProps) {
  const { onOpen, encounterId, chartCode, sectionCode } = props;

  const { data } = useChartSectionNotesCount({
    encounterId,
    chartCode,
    sectionCode,
  });

  const sectionNotesCount = data?.data || 0;

  return (
    <IconButton
      aria-label="notes"
      icon={
        <Icon
          as={EmrNotes}
          color={
            sectionNotesCount && sectionNotesCount > 0 ? "blue" : "gray.450"
          }
          fontSize="1.25rem"
        />
      }
      variant="icon"
      fontSize="1.4375rem"
      minWidth="unset"
      onClick={(e) => {
        e.preventDefault();
        onOpen();
      }}
    />
  );
}

function ChartNavigationButton(props: ChartNavigationButtonProps) {
  const {
    label,
    labelIcon,
    sectionId,
    hasNotes,
    hasAudits,
    isShared,
    to,
    chartCode,
    encounterId,
    hasDiagrams,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const location = useLocation();
  const fromAudits = location.pathname.includes("audits");

  const showNotes = hasNotes && fromAudits;
  const showAudits = hasAudits && fromAudits;
  const showGeneralNotesModal = showNotes && props.notesType === "general";
  const showSharedNotesModal = showNotes && props.notesType === "shared";
  const showOrderNotesModal = showNotes && props.notesType === "order";

  return (
    <>
      <NavigationButton to={to} key={to}>
        <NavigationButtonContent label={label} labelIcon={labelIcon} />
        <HStack spacing="14px">
          {label === "Vitals" && <VitalsTimerBadge encounterId={encounterId} />}
          {hasDiagrams && !fromAudits && (
            <DiagramsButton encounterId={encounterId} chartCode={chartCode} />
          )}

          {showAudits ? (
            isShared === true ? (
              <AuditCommentsSharedSectionIndicator
                sectionCode={sectionId}
                encounterId={encounterId}
                isFromSideBar={true}
              />
            ) : (
              <AuditCommentsSectionIndicator
                chartCode={chartCode}
                sectionCode={sectionId}
                encounterId={encounterId}
                isFromSideBar={true}
              />
            )
          ) : (
            ""
          )}

          {hasNotes && showNotes && (
            <ChartNotesButton
              onOpen={onOpen}
              encounterId={encounterId}
              chartCode={chartCode}
              sectionCode={sectionId}
            />
          )}
        </HStack>
      </NavigationButton>
      {showGeneralNotesModal && (
        <ChartNotesModal
          chartId={chartCode}
          isOpen={isOpen}
          encounterId={encounterId}
          onClose={onClose}
          sectionId={sectionId}
          title={label}
        />
      )}
      {showSharedNotesModal && (
        <SharedChartNotesModal
          isOpen={isOpen}
          encounterId={encounterId}
          onClose={onClose}
          sectionCode={sectionId}
          title={label}
        />
      )}
      {showOrderNotesModal && (
        <OrderChartNotesModal
          isOpen={isOpen}
          onClose={onClose}
          encounterId={encounterId}
          chartCode={chartCode || ""}
          orderType={props.orderType}
          title={label}
        />
      )}
    </>
  );
}

function ChartsSidebar(props: ChartsSidebarProps) {
  const { children } = props;
  const { encounterId = "" } = useParams<{ encounterId: string }>();
  const navigate = useNavigate();
  const { scope } = usePermissions();
  const location = useLocation();
  const state = location.state as {
    fromLabel?: string;
    fromRoute?: string;
  } | null;
  const [isEditMode, setIsEditMode] = React.useState(false);
  const { ref, scrollbarWidth } = useScrollbarWidth<HTMLDivElement>();
  const contentPadding = `12px ${24 - scrollbarWidth}px 8px 24px`;

  const fromAudits = location.pathname.includes("audits");
  const fromLabs = location.pathname.includes("labs");
  const fromRadiology = location.pathname.includes("radiology");
  const fromInProcess = location.pathname.includes("in-process");
  const fromOpenCharts = location.pathname.includes("open-charts");

  const fromLabel = fromAudits
    ? "Audits"
    : fromInProcess
    ? "InProcess"
    : fromLabs
    ? "Labs"
    : fromRadiology
    ? "Radiology"
    : fromOpenCharts
    ? "Open Charts"
    : "InProcess";
  const fromRoute = fromAudits
    ? "/audits"
    : fromInProcess
    ? "/in-process"
    : fromLabs
    ? "/labs"
    : fromRadiology
    ? "/radiology"
    : fromOpenCharts
    ? "/open-charts"
    : "/in-process";

  const addDeletePermission =
    scope("nurse:adddelete").isAccessible ||
    scope("provider:adddelete").isAccessible;

  return (
    <Sidebar>
      <SidebarActions>
        <BackButton
          onClick={() =>
            navigate(state?.fromRoute ? state?.fromRoute : fromRoute, {
              replace: true,
            })
          }
        >
          {state?.fromLabel ? state?.fromLabel : fromLabel}
        </BackButton>
        <ChartSectionHeaderMenu encounterId={encounterId} />
      </SidebarActions>
      <SidebarHeader padding="0 17px">
        <SidebarChartPatientCard patientId={encounterId} marginBottom="10px" />
      </SidebarHeader>
      <SidebarContent
        ref={ref}
        padding={contentPadding}
        style={{ scrollbarGutter: "auto" }}
        overflowX="hidden"
      >
        <ChartPicker isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
        <Divider
          margin="10px -7px 10px -7px"
          borderColor="gray.500"
          width="unset"
        />
        {isEditMode && addDeletePermission ? (
          <AddNewChartButton encounterId={encounterId} />
        ) : (
          children
        )}
      </SidebarContent>
      <SidebarBottom>
        <FaqButton />
        <SidebarUserView />
      </SidebarBottom>
    </Sidebar>
  );
}

export type { ChartsSidebarProps, ChartNavigationButtonProps };
export { ChartsSidebar, ChartNavigationButton };
