import {
  Button,
  chakra,
  Flex,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useToast, useYupValidationResolver } from "hooks";
import { genericErrors } from "messages";
import { formMessages } from "messages/form";
import {
  useCreatePharmacy,
  useEditPharmacy,
  usePharmacy,
  useRemovePreferredPharmacy,
} from "modules/charts-shared/api";
import { PharmacyForm } from "modules/charts-shared/types";
import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  FormControl,
  FormSelect,
  Loading,
  NumberInputMask,
  ToolbarHeader,
} from "shared";
import { enumMapper } from "utils";
import { phoneSchema } from "validators";
import * as Yup from "yup";

const schema = Yup.object().shape({
  ncpdpId: Yup.string().nullable(),
  name: Yup.string().required(formMessages.required("Name")),
  phone: phoneSchema(formMessages.validPhone("Phone")),
  fax: Yup.string().nullable(),
  address: Yup.string().nullable(),
  suite: Yup.string().nullable(),
  city: Yup.string().nullable(),
  state: Yup.mixed().nullable(),
  zip: Yup.string().nullable(),
});

type AddEditPharmacyModalProps = {
  encounterId?: string;
  isModalOpen: boolean;
  onModalClose: () => void;
  pharmacyId?: string;
  isEdigintCurrentPharmacy?: boolean;
};

const usStateOptions = enumMapper.getOptions("usState");

function AddEditPharmacyModalContent(props: AddEditPharmacyModalProps) {
  const {
    onModalClose,
    pharmacyId = "",
    encounterId,
    isEdigintCurrentPharmacy,
  } = props;

  const toast = useToast();
  const resolver = useYupValidationResolver(schema);
  const {
    register,
    control,
    formState: { isValid, errors },
    handleSubmit,
    reset,
  } = useForm<PharmacyForm>({
    resolver,
    mode: "onChange",
    defaultValues: {},
  });

  const { data: pharmacyData, isLoading: isPharmacyDataLoading } = usePharmacy(
    pharmacyId,
    { enabled: !!pharmacyId }
  );

  const {
    mutateAsync: removePreferredPharmacy,
    isLoading: isRemovingPharmacy,
  } = useRemovePreferredPharmacy(encounterId || "");

  const { mutateAsync: createPharmacy, isLoading: isCreatingPharmacy } =
    useCreatePharmacy();
  const { mutateAsync: editPharmacy, isLoading: isEditingPharmacy } =
    useEditPharmacy(pharmacyId, encounterId || "");

  React.useEffect(() => {
    if (pharmacyData?.data) {
      const { name, phone, fax, address, suite, city, state, zip, ncpdpId } =
        pharmacyData.data;
      reset({
        ncpdpId,
        name,
        phone,
        fax,
        address,
        suite,
        city,
        state: enumMapper.valueToOption("usState", state),
        zip,
      });
    }
  }, [pharmacyData, reset]);

  const isUpdating = isCreatingPharmacy || isEditingPharmacy;

  const onSubmit: SubmitHandler<PharmacyForm> = async (dataForm) => {
    try {
      const payload = {
        ...dataForm,
        state:
          dataForm.state != null
            ? enumMapper.toDisplay("usState", dataForm.state.value)
            : "",
      };

      if (!!pharmacyId) {
        await editPharmacy({ id: pharmacyId, ...payload });
      } else {
        await createPharmacy(payload);
      }

      toast({
        status: "success",
        description: !!pharmacyId
          ? formMessages.updateSuccess("Pharmacy")
          : formMessages.createSuccess("Pharmacy"),
      });
      onModalClose();
    } catch (error) {
      if (error instanceof Error) {
        toast({
          status: "error",
          description: error.message || genericErrors.unknownError,
        });
      }
    }
  };

  const handleOpenPahrmacyList = () => {
    const btn = document.getElementById("open-pharmacy-list-btn");
    btn?.click();
    onModalClose();
  };

  const onRemovePreferredPharmacy = React.useCallback(async () => {
    try {
      await removePreferredPharmacy({});
      toast({
        status: "success",
        description: formMessages.deleteSuccess("Preferred Pharmacy"),
      });
      onModalClose();
    } catch (error) {
      if (error instanceof Error) {
        toast({
          status: "error",
          description: error.message || genericErrors.unknownError,
        });
      }
    }
  }, [removePreferredPharmacy, onModalClose, toast]);

  return (
    <>
      <ModalHeader>
        <ToolbarHeader
          titleText={
            <chakra.span fontSize="1.0625rem" fontWeight="600">
              {!pharmacyId
                ? "Add New Preferred Pharmacy"
                : "Edit Preferred Pharmacy"}
            </chakra.span>
          }
          leftButtons={[
            <Button key="cancelBtn" onClick={onModalClose}>
              Cancel
            </Button>,
          ]}
          rightButtons={[
            <Button
              key="doneBtn"
              _disabled={{
                color: "gray.700",
              }}
              isDisabled={!isValid}
              onClick={handleSubmit(onSubmit)}
              isLoading={isUpdating}
            >
              Done
            </Button>,
          ]}
        />
      </ModalHeader>
      <ModalBody>
        {isPharmacyDataLoading ? (
          <Loading />
        ) : (
          <form>
            <VStack alignItems="stretch" spacing="18px" marginBottom="27px">
              <FormControl
                label="NCPDPID"
                error={errors.ncpdpId?.message}
                flex="1"
                w="50%"
              >
                <Input {...register("ncpdpId")} />
              </FormControl>
              <FormControl
                label="Name"
                error={errors.name?.message}
                flex="2"
                isRequired
              >
                <Input {...register("name")} />
              </FormControl>
              <HStack alignItems="stretch" spacing="18px">
                <FormControl
                  label="Phone #"
                  error={errors.phone?.message}
                  flex="2"
                >
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <NumberInputMask
                        type="text"
                        mask="(###) ###-####"
                        inputMode="tel"
                        {...field}
                      />
                    )}
                  />
                </FormControl>
                <FormControl label="Fax #" error={errors.fax?.message} flex="2">
                  <Controller
                    name="fax"
                    control={control}
                    render={({ field }) => (
                      <NumberInputMask
                        type="text"
                        mask="(###) ###-####"
                        inputMode="tel"
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              </HStack>
              <Flex direction="row" gridGap="4">
                <FormControl
                  label="Address"
                  error={errors.address?.message}
                  flex="2"
                >
                  <Input {...register("address")} />
                </FormControl>
                <FormControl
                  label="Suite #"
                  error={errors.suite?.message}
                  minW="120px"
                  width="120px"
                >
                  <Input {...register("suite")} />
                </FormControl>
              </Flex>
              <Flex direction="row" gridGap="4">
                <FormControl
                  label="City"
                  error={errors.city?.message}
                  minW="240px"
                  w="240px"
                >
                  <Input {...register("city")} />
                </FormControl>
                <FormControl
                  label="State"
                  error={errors.state?.message}
                  w="80px"
                  minW="80px"
                >
                  <FormSelect
                    name="state"
                    items={usStateOptions}
                    labelAccessor="label"
                    valueAccessor="value"
                    control={control}
                  />
                </FormControl>
                <FormControl
                  label="ZIP"
                  error={errors.zip?.message}
                  w="140px"
                  minW="140px"
                >
                  <Input type="number" {...register("zip")} />
                </FormControl>
              </Flex>
            </VStack>
          </form>
        )}
        {isEdigintCurrentPharmacy && (
          <Flex direction="column" rowGap="0.5rem">
            <Button variant="outline" onClick={handleOpenPahrmacyList}>
              Go to Pharmacy List
            </Button>
            <Button
              colorScheme="red"
              variant="outline"
              onClick={onRemovePreferredPharmacy}
              isLoading={isRemovingPharmacy}
            >
              Delete Pharmacy for this Patient
            </Button>
          </Flex>
        )}
      </ModalBody>
    </>
  );
}

function AddPharmacyModal(props: AddEditPharmacyModalProps) {
  const { isModalOpen, onModalClose, encounterId } = props;

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onModalClose}
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent maxWidth="540px">
        {isModalOpen && (
          <AddEditPharmacyModalContent
            isModalOpen={isModalOpen}
            onModalClose={onModalClose}
            encounterId={encounterId}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

function EditPharmacyModal(props: AddEditPharmacyModalProps) {
  const {
    isModalOpen,
    onModalClose,
    pharmacyId,
    encounterId,
    isEdigintCurrentPharmacy = false,
  } = props;

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onModalClose}
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent maxWidth="540px">
        {isModalOpen && (
          <AddEditPharmacyModalContent
            isModalOpen={isModalOpen}
            onModalClose={onModalClose}
            encounterId={encounterId}
            pharmacyId={pharmacyId}
            isEdigintCurrentPharmacy={isEdigintCurrentPharmacy}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { AddPharmacyModal, EditPharmacyModal };
