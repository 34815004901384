import * as React from "react";

import {
  Box,
  chakra,
  ComponentWithAs,
  HStack,
  Icon,
  IconButton,
  IconProps,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { IntegrationStatus } from "enums";
import { SquareInfo } from "icons";
import { formMessages, genericErrors } from "messages";
import {
  useEncounterIntegrations,
  useUpdateEncounterIntegrationHL7,
  useUpdateEncounterIntegrationAYVA,
} from "modules/reception/api";
import { Card, CardProps, Loading, SingleActionDialog } from "shared";
import { extractApiErrorMessage } from "utils";

type EncounterIntegrationButtonProps = {
  type: string;
  label: string;
  status: IntegrationStatus;
  isLoading: boolean;
  icon: ComponentWithAs<"svg", IconProps>;
  onUpdateEncounterIntegration?: (type: string) => void;
} & CardProps;

function EncounterIntegrationButton(props: EncounterIntegrationButtonProps) {
  const {
    type,
    label,
    status,
    icon,
    isLoading,
    onUpdateEncounterIntegration = () => null,
    ...cardProps
  } = props;

  const INTEGRATION_COLOR: Record<IntegrationStatus, string> = {
    Success: "green",
    Failure: "red",
    Disconnected: "lightgray",
    Warning: "yellow",
  };

  const INTEGRATION_TEXT: Record<IntegrationStatus, string> = {
    Success: "Information Sent",
    Failure: "Information Failed",
    Disconnected: "No Information Sent",
    Warning: "Partial Information Sent",
  };

  return (
    <Card
      margin="3px"
      borderRadius="10px"
      backgroundColor={INTEGRATION_COLOR[status]}
      {...cardProps}
    >
      {isLoading ? (
        <Loading spinnerProps={{ color: "white" }} />
      ) : (
        <HStack
          spacing="10px"
          cursor={`${status !== "Success" ? "pointer" : "auto"}`}
          onClick={() =>
            status !== "Success" ? onUpdateEncounterIntegration(type) : null
          }
        >
          <Box display="flex" justifyContent="space-between">
            <IconButton
              aria-label="integration"
              icon={<Icon as={icon} color="white" fontSize="1.5rem" />}
              variant="ghost"
              height="100%"
              width="2rem"
              minWidth="unset"
              cursor={`${status !== "Success" ? "pointer" : "auto"}`}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            paddingBottom="5px"
          >
            <VStack spacing="0px">
              <chakra.span
                width="100%"
                color="white"
                fontSize="17px"
                fontWeight="600"
                textAlign="start"
                lineHeight="1.3em"
              >
                {label}
              </chakra.span>
              <chakra.span
                width="100%"
                color="white"
                fontSize="13px"
                fontWeight="400"
                textAlign="start"
                lineHeight=".85em"
              >
                {INTEGRATION_TEXT[status]}
              </chakra.span>
            </VStack>
          </Box>
        </HStack>
      )}
    </Card>
  );
}

type EncounterIntegrationCardsProps = {
  encounterId: string;
};

function EncounterIntegrationCards(props: EncounterIntegrationCardsProps) {
  const { encounterId } = props;

  const { data, isLoading: isLoadingEncounterIntegrations } =
    useEncounterIntegrations(encounterId);

  const INTEGRATION_ITEMS = [
    {
      type: "ayva",
      label: "Ayva",
      status: data?.data?.ayvaConnectionStatus ?? "Disconnected",
    },
    {
      type: "lis",
      label: "LIS",
      status: data?.data?.lisADTConnectionStatus ?? "Disconnected",
    },
    {
      type: "ramsoft",
      label: "Ramsoft",
      status: data?.data?.ramsoftADTConnectionStatus ?? "Disconnected",
    },
  ];

  const {
    mutateAsync: updateIntegrationRamsoft,
    isLoading: isLoadingUpdateIntegrationRamsoft,
  } = useUpdateEncounterIntegrationHL7(encounterId);

  const {
    mutateAsync: updateIntegrationAYVA,
    isLoading: isLoadingUpdateIntegrationAYVA,
  } = useUpdateEncounterIntegrationAYVA(encounterId);

  const toast = useToast();

  const onUpdateIntegrationRamsoft = async () => {
    try {
      await updateIntegrationRamsoft({
        encounterId: encounterId,
        provider: "Ramsoft",
        method: "ADT",
        protocol: "A08",
      });
      toast({
        description: formMessages.updateSuccess("Integration status updated"),
      });
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const {
    isOpen: isOpenAyvaModal,
    onClose: onCloseAyvaModal,
    onOpen: onOpenAyvaModal,
  } = useDisclosure();

  const [ayvaModalLabel, setAyvaModalLabel] = React.useState("");
  const [ayvaModalTitle, setAyvaModalTitle] = React.useState("");
  const [ayvaModalContent, setAyvaModalContent] = React.useState<string[]>([]);

  const onUpdateIntegrationAYVA = async () => {
    try {
      const ayvaRequest = await updateIntegrationAYVA({
        encounterId: encounterId,
      });

      setAyvaModalTitle(ayvaRequest.data?.messageInfoTitle ?? "");
      setAyvaModalLabel(ayvaRequest.data?.messageInfoAction ?? "");
      setAyvaModalContent(ayvaRequest.data?.messageInfoMessages ?? []);
      onOpenAyvaModal();
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  return (
    <HStack spacing="15px">
      {INTEGRATION_ITEMS.map((item) => (
        <Box key={item.type} display="flex" justifyContent="space-between">
          <EncounterIntegrationButton
            type={item.type}
            label={item.label}
            status={item.status}
            icon={SquareInfo}
            sx={{
              width: "200px",
              padding: "4px 16px 4px 16px",
            }}
            isLoading={
              isLoadingEncounterIntegrations ||
              (item.type === "ramsoft" && isLoadingUpdateIntegrationRamsoft) ||
              (item.type === "ayva" && isLoadingUpdateIntegrationAYVA)
            }
            onUpdateEncounterIntegration={(integration) => {
              switch (integration) {
                case "lis":
                  // onUpdateIntegrationLIS();
                  break;
                case "ramsoft":
                  onUpdateIntegrationRamsoft();
                  break;
                case "ayva":
                  onUpdateIntegrationAYVA();
                  break;
              }
            }}
          />
        </Box>
      ))}

      {isOpenAyvaModal && (
        <SingleActionDialog
          title={ayvaModalTitle}
          actionLabel={ayvaModalLabel}
          actionStyles={{ color: "#007AFF", textTransform: "capitalize" }}
          content={ayvaModalContent}
          onClose={onCloseAyvaModal}
          isOpen={isOpenAyvaModal}
        />
      )}
    </HStack>
  );
}

export { EncounterIntegrationCards, EncounterIntegrationButton };
