import * as React from "react";
import {
  FormControl as CkFormControl,
  FormControlProps as CkFormControlProps,
} from "@chakra-ui/react";
import { FormErrorMessage } from "./FormErrorMessage";
import { Label } from "../label";

type FormControlProps = {
  label?: string;
  error?: string;
} & CkFormControlProps;

const FormControl = React.forwardRef<HTMLDivElement, FormControlProps>(
  (props, ref) => {
    const { label, error, children, ...formControlProps } = props;

    return (
      <CkFormControl
        ref={ref}
        display="flex"
        flexDirection="column"
        {...formControlProps}
        isInvalid={Boolean(error) || formControlProps.isInvalid}
      >
        {label && <Label lineHeight="1.1875rem">{label}</Label>}
        {children}
        <FormErrorMessage
          position="absolute"
          bottom="calc(-0.75rem - 8px)"
          width="100%"
          title={error}
          marginTop={0}
        >
          {error}
        </FormErrorMessage>
      </CkFormControl>
    );
  }
);

FormControl.displayName = "FormControl";

export { FormControl };
export type { FormControlProps };
