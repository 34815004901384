import * as React from "react";
import {
  BaseVitalsInputModalProps,
  VitalsInputModal,
  VitalsInputModalKeypad,
  VitalsInputModalValueBox,
} from "./VitalsInputModal";
import { Button } from "@chakra-ui/react";
import { Close } from "icons";

function SlashVitalsInputModal(props: BaseVitalsInputModalProps) {
  const { onSave, initialValue, ...vitalsInputModalProps } = props;
  const { onClose } = vitalsInputModalProps;
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <VitalsInputModal {...vitalsInputModalProps}>
      <VitalsInputModalValueBox>{value}</VitalsInputModalValueBox>
      <VitalsInputModalKeypad
        customKeyLabel="/"
        onClickCustomKey={() => setValue((prev) => `${prev}/`)}
        onClickKey={(x) => setValue((prev) => `${prev}${x}`)}
        onSave={() => {
          onSave(value);
          onClose();
        }}
        onDelete={() => setValue((prev) => prev.slice(0, prev.length - 1))}
      />
      <Button
        onClick={onClose}
        variant="solid"
        m={0}
        minW="unset"
        backgroundColor="gray.350"
        w="30px"
        h="30px"
        position="absolute"
        top={3}
        right={3}
        maxH="unset"
        colorScheme="gray"
      >
        <Close width="10px" height="10px" color="gray.600" />
      </Button>
    </VitalsInputModal>
  );
}

export { SlashVitalsInputModal };
