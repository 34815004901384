import { OrderFormElementCategory } from "enums";

const ORDER_TYPE_TEXT: Record<OrderFormElementCategory, string> = {
  CT_SCAN: "CT",
  XRAY: "XR",
  MRI: "",
  Ultrasound: "",
};

const ORDER_TYPE_BORDER_RADIUS: Record<OrderFormElementCategory, string> = {
  CT_SCAN: "15px",
  XRAY: "5px",
  Ultrasound: "",
  MRI: "",
};

export { ORDER_TYPE_TEXT, ORDER_TYPE_BORDER_RADIUS };
