import { Box, HStack, Icon, Image, Text, VStack } from "@chakra-ui/react";
import { Button, StaffAvatar } from "shared";
import {
  useGetOrderProviderAuthorization,
  useSignOrderProviderAuthorization,
} from "modules/charts-shared/api";

import { EmrSignature } from "@medstonetech/slate-icons";
import React from "react";
import { USERID_CLAIMS } from "system-constants";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useAuth0 } from "@auth0/auth0-react";
import { useToast } from "hooks";

type OrderProviderAuthorizationProps = {
  encounterId: string;
};

const OrderProviderAuthorization = ({
  encounterId,
}: OrderProviderAuthorizationProps) => {
  const toast = useToast();
  const { user: currentUser } = useAuth0();
  const userId = currentUser ? currentUser[USERID_CLAIMS] : "";

  const {
    data,
    isLoading,
    error: dataError,
  } = useGetOrderProviderAuthorization(encounterId);

  const response = data?.data;

  const { mutateAsync: signOrders, isLoading: isSignOrderLoading } =
    useSignOrderProviderAuthorization(encounterId);

  const handleSignOrders = async () => {
    try {
      await signOrders(null);
      toast({
        status: "success",
        description: formMessages.updateSuccess("Provider Authorization"),
      });
    } catch (error) {
      toast({ status: "error", description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (dataError) {
      toast({
        status: "error",
        description: extractApiErrorMessage(dataError),
      });
    }
  }, [dataError, toast, isLoading, response]);

  if (!response?.user) {
    return <></>;
  }

  return (
    <Box height="150px" p={6} width="100%" bgColor="white">
      <VStack width="100%" spacing={6}>
        <HStack display="flex" justifyContent="start" width="100%" spacing={4}>
          <Text fontWeight="600" fontSize="18px">
            Provider Authorization
          </Text>
          <Text fontWeight="600" fontSize="16px">
            I hereby approve all orders for this patient.
          </Text>
        </HStack>

        <HStack
          pl={4}
          display="flex"
          justifyContent="start"
          justifyItems="center"
          width="100%"
          spacing={40}
        >
          <HStack>
            <StaffAvatar
              w="40px"
              h="40px"
              size="xs"
              fontSize=".75rem"
              userName={response?.user?.fullName}
              profileUrl={response?.user?.pictureUrl}
            />
            <VStack spacing="0" alignItems="flex-start">
              <Box
                fontSize="1.0625rem"
                fontWeight="600"
                lineHeight="1.5rem"
                color="black"
              >
                {response?.user?.fullName}
              </Box>
              <Box
                fontSize="0.875rem"
                fontWeight="400"
                lineHeight="1rem"
                color="gray.700"
              >
                {response?.user?.teams}
              </Box>
            </VStack>
          </HStack>

          <HStack spacing={10}>
            <Text fontWeight="600" fontSize="16px" color="gray.700">
              E-signed by:
            </Text>
            {response?.isAuthorized ? (
              <Box minW={300} maxW={400} minH={50}>
                <Image
                  src={response?.user?.signature}
                  height="100%"
                  width="100%"
                />
              </Box>
            ) : (
              <Button
                variant="label"
                color="blue"
                isDisabled={
                  isLoading ||
                  isSignOrderLoading ||
                  !response?.user?.signature ||
                  userId !== response.user.id
                }
                isLoading={isLoading || isSignOrderLoading}
                onClick={handleSignOrders}
              >
                <Icon as={EmrSignature} fontSize="1.5rem" color="blue" m={2} />
                Add Signature
              </Button>
            )}
          </HStack>
        </HStack>
      </VStack>
    </Box>
  );
};

export { OrderProviderAuthorization };
